import React from 'react';

import { Container } from 'semantic-ui-react';

import {useWindowWidth} from '../global/Global'

import Images from '../pages/Images'
import Submit from '../pages/Submit'

import FullHeaderBlock from './FullHeaderBlock';
import BackgroundHeaderBlock from './BackgroundHeaderBlock';
import WizardPageManager from './WizardPageManager';

const steps =
[
  //make sure both pageNames are the same
  {label: 'Photos', pageName:'Pho', component: <Images pageName='Pho'/>},
  {label: 'Send', pageName:'Send', component: <Submit pageName='Send'/>, nextText:"Send"}, 
]

const UploadWizard =()=> {

  const  width  = useWindowWidth();

  function firstPageHeader() {
    return (
      <BackgroundHeaderBlock width={width}/> 
    )
  }

  function PageHeader() {
    return (
      <FullHeaderBlock content1='Project ID 31436' content2='Image datasets as machine learning data for countering child sexual exploitation and abuse.' full/>
    )
  }
  return (
    <Container textAlign='center' >
      <Container className='step-progress' fluid>
        <WizardPageManager steps={steps} validate={true} showSteps={true} hideBackButtonOnLast={false} firstPageHeader={firstPageHeader()} pageHeader={PageHeader()}/>
      </Container>
    </Container>
  )
}
export default UploadWizard;
