import * as React from 'react';
import {
    HashRouter,
    Routes,
    Route
  } from "react-router-dom";
import { createRoot } from 'react-dom/client';

import ScrollToTop from './components/ScrollToTop'

import App  from './pages/App';
import RegistrationWizard  from './components/RegistrationWizard';
import UploadWizard  from './components/UploadWizard';
import RegistrationSubmitted from './pages/RegistrationSubmitted'
import CheckRegistration from './pages/CheckRegistration'
import FAQ from './pages/FAQ'
import Goals from './pages/Goals'
import Final from './pages/Final'
import Contact from './pages/Contact'

import './index.css';
import './assets/inlines.css';

import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react'

const instance = createInstance({
  urlBase: process.env.REACT_APP_ANALYTICS_URL ?? 'unknown analytics url',
  siteId: process.env.REACT_APP_ANALYTICS_DOMAIN !== undefined ? parseInt(process.env.REACT_APP_ANALYTICS_DOMAIN) : 1,

  //userId: 'UID76903202', // optional, default value: `undefined`.
  //trackerUrl: 'http://118.138.245.204/tracking.php', // optional, default value: `${urlBase}matomo.php`
  //srcUrl: 'http://118.138.245.204/tracking.js', // optional, default value: `${urlBase}matomo.js`
  //disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  //heartBeat: { // optional, enabled by default
  //  active: true, // optional, default value: true
  //  seconds: 10 // optional, default value: `15
  //},
  linkTracking: false, // optional, default value: true
  //configurations: { // optional, default value: {}
  //  // any valid matomo configuration, all below are optional
  //  disableCookies: true,
  //  setSecureCookie: true,
  // setRequestMethod: 'POST'
  //}
})

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
    <MatomoProvider value={instance}>
        <HashRouter basename="/">
            <ScrollToTop/>
            <Routes>
                <Route path="/registration" element={<RegistrationWizard />} />
                <Route path="/registrationsubmitted" element={<RegistrationSubmitted />} />
                <Route path="/checkRegistration" element={<CheckRegistration />} />
                <Route path="/upload" element={<UploadWizard />} />
                <Route path="/final" element={<Final />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/goals" element={<Goals />} />
                <Route path="/contactus" element={<Contact type='Contact Us' back='/'/>} />
                <Route path="/feedback" element={<Contact type='Feedback' back='/final'/>} />            
                <Route path="/" element={<App/>} />            
            </Routes>         

        </HashRouter>
    </MatomoProvider>
);
