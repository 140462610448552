import React, {useContext, useEffect}  from 'react'

import { useMatomo } from '@jonkoops/matomo-tracker-react'

import { Container, Header, Message, Dimmer, Loader, Grid, Image} from 'semantic-ui-react';

import {Observer} from 'mobx-react'

import {getMyHost, useWindowWidth} from '../global/Global'

import Stores  from "../stores/StoreCollection";

import CIStore, {PROCESS_STATUS}  from "../stores/CIStore"
import FullHeaderBlock from '../components/FullHeaderBlock';

import blobby from '../assets/blobhappy.png';
import blobbypanic from '../assets/blobbypanic.png';


const RegistrationSubmitted = (props) => {

  const store:CIStore  = useContext(Stores).ciStore 

  const consentUrl = `${getMyHost()}#/registration`

  const  width  = useWindowWidth();

  
  // Track page view
  const { trackPageView } = useMatomo()
  useEffect(() => {
    trackPageView({
      documentTitle: 'Registration submitted',
    })
  })

  const Blobby = (props) => {

      return (

          <Image src={blobby} size='small' />
      )
  }

  return (
    <Observer>{() => 
      <Container textAlign='left'>
        <FullHeaderBlock content1='' />

        {store.registered ===  PROCESS_STATUS.NO &&
          <div>
              <Dimmer active >
              <Loader size='small' inline='centered' inverted>
                We are sending you a confirmation email.  This may take a few moments...
                </Loader>
              </Dimmer>              
          </div>
        }        

        {store.registered ===  PROCESS_STATUS.FAILED &&
            <Message negative>
              <Message.Header>Oops, consent submission for My PicturesMatter unsuccessful.</Message.Header>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={width > 500 ? 14 : 12} verticalAlign="middle">
                  <p> 
                      We're very sorry, but there seems to be something wrong with our server.  We're looking into it, please try again later. 
                  </p>
                  </Grid.Column>
                  <Grid.Column width={width > 500 ? 2 : 4}  >
                    <Image src={blobbypanic} size='tiny'/>
                  </Grid.Column>                  
                </Grid.Row>
              </Grid>

            </Message>
        }  

        {store.registered ===  PROCESS_STATUS.YES &&
          <React.Fragment>                      

            <Header textAlign='center'size='huge'>Research Consent Submitted</Header>

            <Grid verticalAlign='middle'>
              <Grid.Column width={10}>

                <Header size="medium">Almost there!</Header>
                <p>We have sent an email confirming your research consent and contact preferences to: <b>{store.email}</b>.</p>

                <p><b>To add photos to the research dataset, click on the “Upload Photos” button in that email.</b></p>
                <p>If the email address shown above is incorrect, please resend your consent form with the correct address by following this link: &nbsp;
                <a href={consentUrl}>back to the consent form</a>.
                </p>
                <p>If the email address above is correct, but you did not receive an email confirmation in your inbox, please check your spam folder. If no message has arrived, please let us know so that we can investigate the issue: <a href="mailto:mypicturesmatter@ailecs.org">mypicturesmatter@ailecs.org</a> 
                </p>
              </Grid.Column>
              <Grid.Column width={6}>
                  <Blobby/>
              </Grid.Column>
            </Grid>
          </React.Fragment>
        }
        
      </Container>
    }</Observer>    
   ) 


}

export default RegistrationSubmitted;