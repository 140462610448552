import React, {useEffect, useContext}  from 'react'

import { useMatomo } from '@jonkoops/matomo-tracker-react'

import { Container, Header, } from 'semantic-ui-react';

import Stores  from "../stores/StoreCollection";   

const Explanation2 = (props) => {
  const valStore = useContext(Stores).valStore
  
  // Track page view
  const { trackPageView } = useMatomo()

  useEffect(() => {
        trackPageView({
      documentTitle: 'Explanation page 2',
    })
    
    validate()
  }); 

  function validate () {
    //wizard manager will use the name of this default export
    valStore.setPageValid(props.pageName, true)
  }

  return (
    <Container textAlign='left'>
     
      <Header textAlign='center'size='huge'>Data Handling</Header>

      <Header size="medium">What happens after I upload my photos?</Header>
        <ul>
          <li>
            You will receive an email receipt with a PDF record of the photo(s) you have submitted, and details for withdrawing consent if you no longer want your photos to be used in the research.
          </li>
          <li>
            If you request, we will remove your photo(s) from the active research dataset. This instruction will not apply retrospectively: results that have already been obtained using your photo(s) will not be excluded from our study.

          </li>
          <li>
            If you agree to be contacted, we will periodically reach out to you as a reminder that your images are being used in the dataset, and to provide an opportunity to opt-out from further participation. We will do this not more than once per year, and not less than once every three years. If we do not receive a response to this communication, we will understand that your consent is still valid.
          </li>
        </ul>

        <Header size="medium">How will my photos be stored and shared?</Header>
        <ul>
          <li>
            Photos and any other information you provide will be stored by the AiLECS Lab using Monash University IT infrastructure and/or secure cloud services with servers located in Australia.  The dataset will not be hosted in any ‘open’ repositories, however a description of the dataset may be visible in public data registries.
          </li>
          <li>
            Access will be restricted to authorised members of the research team. Other researchers may only be granted access to images conditional on the approval of formal ethics processes, where you have given permission. You can update your data sharing preferences at any time by emailing us at <a href="mailto:mypicturesmatter@ailecs.org">mypicturesmatter@ailecs.org</a>.
          </li>
          <li>
            Research data will be kept for a minimum of 5 years after completion of any projects that utilise the dataset. Records documenting consent will be kept until the research dataset has been deleted.
          </li>
        </ul>

        <Header size="medium">Personal information</Header>
        <ul>
          <li>
            Your email address will not be part of the research dataset and will not be disclosed to researchers or used for any purpose other than verifying consent, and communicating with you in line with your preferences. We will not embed personally identifiable information as image metadata. 
          </li>
          <li>
            We will exercise care and diligence to comply with data handling standards, and regulations in relation to any photo(s) you contribute to the project. We may occasionally need to include photos in publications or reports relating to the research; if so, we will maintain the anonymity of persons depicted.
          </li>
        </ul>
    </Container>
   ) 


}

export default Explanation2;