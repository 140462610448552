import React, {useState, useContext, useEffect}   from 'react'
import {Navigate } from "react-router-dom"; 

import { useMatomo } from '@jonkoops/matomo-tracker-react'

import { Container, Header, Button, Input, Image, Form, TextArea, Dimmer } from 'semantic-ui-react';

import {useWindowWidth} from '../global/Global'
import BackgroundHeaderBlock from '../components/BackgroundHeaderBlock'
import contactblobby from '../assets/Contact.png'

import Stores  from "../stores/StoreCollection";
import CIStore  from "../stores/CIStore";

  const Contact = (props) => {
    const [doBack, setBack] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [msg, setMsg] = useState('')
    const [sent, setSent] = useState(false)

    const store:CIStore = useContext(Stores).ciStore

    // Track page view
    const { trackPageView } = useMatomo()
    useEffect(() => {
      trackPageView({
        documentTitle: 'Contact Us',
      })
    })     

    const  width  = useWindowWidth();

    function goBack() {
      setBack(true)
    }

    function doSubmit() {
      store.sendContact(name, email, msg, props.type)
      setSent(true)
    }

    return (      
      <Container textAlign='left'>
        {doBack && 
          <Navigate to={props.back}/>
        }

        <BackgroundHeaderBlock width={width}/>     

        {sent && 
          <React.Fragment>
              <Dimmer active >
                <p>Thanks for your message!  If you provided an email address, you will receieve a copy.</p>
                <Button primary onClick={goBack} floated='right'>Go Back</Button>
              </Dimmer>    
          </React.Fragment>
        }

        {!sent && 
          <React.Fragment>
          <Header textAlign='center'  size='huge' className='humongous marginLeft20'>{props.type} <Image src={contactblobby} width='50px' />  </Header>         

          <p/>
          <p>We'd love to hear from you.  If you include an email address, we'll use it to send you a copy of your message and any replies.
          We will not use your email address for any other purpose, nor disclose it to any other party.
          </p>
          <p>Any feedback you send us about the research project using this form is covered under Monash University's general <a href="https://www.monash.edu/privacy-monash/privacy-collection-statements">Data Protection and Privacy Collection Statements</a>.</p>

          <Form onSubmit={doSubmit}>
            <Form.Field >
              <label>Your Name (Optional)</label>
              <Input placeholder='Enter your name' value={name}  onChange={(evt, data) => setName( data.value)} />
            </Form.Field>
            <Form.Field>
              <label>Your email address (Optional - only if you want a copy of this message and/or reply)</label>
              <Input placeholder='Enter your email address' value={email} onChange={(evt, data) => setEmail( data.value) } />
            </Form.Field>
            <Form.Field>
              <label>What would you like to tell us?</label>
              <TextArea  placeholder='Enter your message'
                value={msg}
                onChange={(evt, data) => setMsg( data.value ? data.value.toString() : '') }
              />  
            </Form.Field>
            <Button positive type='submit'>Send message</Button>
          </Form>

          <Container textAlign='center'>
            <Button primary onClick={goBack} floated='right'>Go Back</Button>
          </Container>
        </React.Fragment>
        }
      </Container>
    ) 
}

export default Contact;