import React, {useState, useContext, useEffect}  from 'react'
import {Navigate } from "react-router-dom"; 
import { Container, Icon, Button, Divider, Image, } from 'semantic-ui-react';

import {Observer} from 'mobx-react'

import blobby from '../assets/blobhappy-left.png';
import Stores  from "../stores/StoreCollection";

enum PROGRESS {
    TODO = 'todo',
    DOING = 'doing',
    DONE = 'done'
}

const WizardPageManager = (props)=> {
    const valStore = useContext(Stores).valStore

    const [steps, setSteps] = useState<any[]>([])
    const [currentStep, setCurrentStep] = useState(0)
    const [doBack, setBack] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    const startStep = props.step ?? 0

    // Initialise steps
    if(steps.length < 1) {
        for(let i = 0; i < props.steps.length; i++) {
            let candidate = props.steps[i]

            if (i < startStep) {
                candidate['progress'] = PROGRESS.DONE
            } else if (i===startStep) {
                candidate['progress'] = PROGRESS.DOING
                setCurrentStep(startStep)
            } else {
                candidate['progress'] = PROGRESS.TODO
            }

            steps.push(candidate)
            setSteps(steps )

            valStore.setPageValid(candidate.pageName, false)
        }        
    }
   
    function jumpToStep(index) {
        let allowJump = true
        
        if (!props.validate) {
            //allow jump if we're not validating
        } else if (index === 0) {            
            // always allow jump back to the first step
        } else {
            //check to make sure all of the preceding steps of the jump step are valid 
            for (let i=0; i < index; i++) {
                if (!valStore.pageValid[steps[i].pageName]) {
                    allowJump = false
                    break
                }
            }
        }

        if (allowJump) {
            if (valStore.pageValid[steps[currentStep].pageName]) {
                steps[currentStep].progress = PROGRESS.DONE                
            } else {
                steps[currentStep].progress = PROGRESS.TODO
            }

            if (steps[index].progress !== PROGRESS.DONE) {
                steps[index].progress = PROGRESS.DOING
            }        
                
            setSteps(steps )
            setCurrentStep(index)
        }   
    }

    //for rendering the navigation
    function getStepProgressClassName(index) {
        let result = 'progtrckr-todo'

        if (index < steps.length) {
            result = 'progtrckr-' + steps[index]['progress']
        } 

        return result
    }

    // render the steps as stepsNavigation
    const renderSteps = () => {
        return props.steps.map((step, i) => (
                <li className={getStepProgressClassName( i)} onClick={(evt) => { jumpToStep(i); }} key={i} value={i}>
                    <em>{i + 1}</em>
                    <span >{steps[i].label}</span>
                </li>
        ));
    };

    function goBack() {
        setBack(true)
    }

    return (
        <Observer>{() => 
            <Container  >
                {doBack && 
                    <Navigate to={props.firstBack}/>
                }
                <Container className='step-progress' fluid>
                    {currentStep === 0 && props.firstPageHeader !== undefined &&
                        props.firstPageHeader 
                    }
                    {currentStep !== 0 && props.pageHeader !== undefined &&
                        props.pageHeader 
                    }
                    {
                    props.showSteps
                        ? 
                        <div>
                                                           
                            <ol className="progtrckr">
                                <li>
                                    <Image src={blobby} width='50px' floated='left' className="steps-blobby"/> 
                                </li>
                                {renderSteps()}

                            </ol>
                        
                        </div>
                        : <span></span>
                    }

                    {steps.map((step, i) => {            
                        if (i === currentStep) {
                             return step['component']
                        } else {
                            return null
                        } 
                    })}
                </Container>

                <Container className="footer-buttons">
                <Divider />
                { (currentStep !== 0 || props.firstBack === undefined) && 
                    <Button
                        type="button"
                        className={currentStep === 0 || (props.hideBackButtonOnLast && currentStep === steps.length-1) ? 'hidden-button' : 'medium'}
                        onClick={() => { jumpToStep(currentStep -1); }}
                        floated='left'
                        id="prev-button"
                    >
                        <Icon name='arrow left' />
                        Previous
                    </Button>
                }
                {currentStep === 0 && props.firstBack !== undefined &&
                    <Button
                        type="button"
                        className= 'medium'
                        onClick={goBack}
                        floated='left'
                        id="prev-button"
                    >
                        <Icon name='arrow left' />
                        Back
                    </Button>
                }                
                <Button
                    type="button"
                    className={currentStep === steps.length-1 ? ' hidden-button' : 'primary medium'}
                    onClick={() => { jumpToStep(currentStep + 1); }}
                    floated='right'
                    id="next-button" 
                    disabled={!valStore.pageValid[steps[currentStep].pageName]}          
                >
                    {steps[currentStep]['nextText'] ? steps[currentStep]['nextText'] : 'Next'}
                    <Icon name='arrow right' />
                </Button>


                </Container>        
            </Container>
      }</Observer>   
    )}

export default WizardPageManager;