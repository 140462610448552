import React, {useState, useContext, useEffect}  from 'react'
import Joi from 'joi';

import { Container, Input, Button, Label, Message, Grid} from 'semantic-ui-react';

import {Observer} from 'mobx-react'

import Stores  from "../stores/StoreCollection";
import {PROCESS_STATUS}  from "../stores/CIStore";

const EMAIL_SCHEMA = Joi.string().email({ tlds: {allow: false}, minDomainSegments:2 }).required()

const SimpleEmailValidationWidget = (props) => {

  const store = useContext(Stores).ciStore

  const [showEmailError, setEmailError] = useState(false)
  const [validEmail, setValidEmail] = useState(false)

  useEffect(() => {
    validate()
  }); 

  function validate () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let {value, error} = EMAIL_SCHEMA.validate(store.email)

    setValidEmail(typeof error === 'undefined')
  }

  function handleEmailUpdate (evt, data) {
    //update the email address
    store.updateEmail(data.value)

    //we're typing the email address so turn the invalid format message off...
    setEmailError(false)

    //and close any internal error messages
    store.setEmailValidationStarted(PROCESS_STATUS.NO)

    validate()
  }

  function launch () {
    props.launchCallback()
  }

  function onKeyDown(e) {
    if(e.key === 'Enter') {
      launch();
    }
  }

  return (
    <Observer>{() => 
    <Container textAlign='left'>

        {store.bundleSubmitted === PROCESS_STATUS.FAILED &&
          <Message negative>
              <Message.Header>Internal System Error</Message.Header>
              <p>Sorry, there's a problem verifying your email address just now. Please try again later. </p>
          </Message>
        }

        { showEmailError  &&
        <div>
          <Label basic color='red' pointing='below' active>
            Please enter a valid Email Address
          </Label><br/>
          </div>            
        }

<Grid >
          <Grid.Row columns={16} only='tabletcomputer'>
            <Grid.Column width={16 }>
              <Input focus placeholder='Email Address' value={store.email} onChange = {handleEmailUpdate} disabled={!props.enabled} />
              <Button positive                    
                    onClick= {launch}
                    disabled={!(props.enabled && validEmail)} 
                    onKeyDown={onKeyDown}  
                    tabIndex="0"     
                    attached='right'   
                  >
                <Button.Content>I Agree</Button.Content>          
              </Button>          
            </Grid.Column>
          </Grid.Row>            
            
          <Grid.Row columns={16} only='tablet'>
            <Grid.Column width={6 }>
              <Input focus placeholder='Email Address' value={store.email} onChange = {handleEmailUpdate} disabled={!props.enabled} />
            </Grid.Column>
            <Grid.Column width={8}>
              <Button positive                    
                    onClick= {launch}
                    disabled={!(props.enabled && validEmail)} 
                    onKeyDown={onKeyDown}  
                    tabIndex="0"        
                  >
                <Button.Content>I Agree</Button.Content>
              </Button>
            </Grid.Column>
          </Grid.Row>     

          <Grid.Row columns={16} only='mobile'>
            <Grid.Column width={16}>
              <Input focus placeholder='Email Address' value={store.email} onChange = {handleEmailUpdate} disabled={!props.enabled} />
            </Grid.Column>
          </Grid.Row>  
          <Grid.Row columns={16} only='mobile'>
            <Grid.Column width={8}>
              <Button positive                    
                    onClick= {launch}
                    disabled={!(props.enabled && validEmail)} 
                    onKeyDown={onKeyDown}  
                    tabIndex="0"        
                  >
                <Button.Content>I Agree</Button.Content>
              </Button>
            </Grid.Column>
          </Grid.Row>                
        </Grid>            
        <p/>
     
      </Container>
    }</Observer>   
  ) 
    
}

export default SimpleEmailValidationWidget;