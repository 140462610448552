import React, {useContext, useEffect}  from 'react'
import {Observer} from 'mobx-react'
import { Navigate, useLocation } from 'react-router-dom';
import { Container, Dimmer, Loader, Message, Grid, Image} from 'semantic-ui-react';

import { useMatomo } from '@jonkoops/matomo-tracker-react'

import blobbypanic from '../assets/blobbypanic.png';

import {useWindowWidth} from '../global/Global'
import Stores  from "../stores/StoreCollection";
import CIStore, {PROCESS_STATUS}  from "../stores/CIStore"
import FullHeaderBlock from '../components/FullHeaderBlock';

const CheckRegistration = (props) => {

  const  width  = useWindowWidth();

  // Track page view
  const { trackPageView } = useMatomo()
  useEffect(() => {
    trackPageView({
      documentTitle: 'Check Registration',
    })
  }) 

    const store:CIStore = useContext(Stores).ciStore  

    function useQueryString() {
      const location = useLocation();
      return new URLSearchParams(location.search);
     }

     const queryString = useQueryString();

     const token:string|null = queryString.get('t')

     if (token === null) {
      store.setRegistered(PROCESS_STATUS.FAILED)
     } else {
      store.checkRegistration(token )
     }


    return (
      <Observer>{() => 
        <Container textAlign='left'>
            <FullHeaderBlock content1='' />
        {store.registered ===  PROCESS_STATUS.NO &&
          <div>
              <Dimmer active >
                <Loader size='small' inline='centered' inverted>
                  Checking your consent and email address. This may take a few moments...
                </Loader>
              </Dimmer>              
          </div>
        }        
        {store.registered ===  PROCESS_STATUS.FAILED &&
        
            <Message negative>
              <Message.Header>Oops, page not found.</Message.Header>

              <Grid>
                <Grid.Row>
                  <Grid.Column width={width > 500 ? 14 : 12}  verticalAlign="middle">
                    <p> 
                      Sorry!  There seems to be a problem with the My Pictures Matter consent url. 
                    </p>
                    <p>
                      Either the link has expired, or you have already contributed photos. 
                    </p>
                    <p>
                      If you have more photos to contribute, please, go to <a href='{process.env.CLIENT_URL}'>mypicturesmatter.org</a> to start another submission.
                    </p>
                  </Grid.Column>
                  <Grid.Column width={width > 500 ? 2 : 4} >
                    <Image src={blobbypanic} size='tiny'/>
                  </Grid.Column>                  
                </Grid.Row>
              </Grid>              
            </Message>
        }   

        {store.registered ===  PROCESS_STATUS.YES &&
            <Navigate to="/upload"/>
        }           
        </Container>
      }</Observer>
    )
} 

export default CheckRegistration;