
import { makeObservable, observable, action, toJS, computed} from "mobx";
import ValidationDAO from '../data/ValidationDAO'
import SubmitDAO from '../data/SubmitDAO'

import IPerson from '../global/IPerson'
import IItem from '../global/IItem'

export enum PERMISSION {
    UNKNOWN = 'Unknown',
    SELF = 'Self',
    OBTAINED = 'Obtained'
  }    

export enum CONSENT {
    UNKNOWN = 'Unknown',
    YES = 'True',
    NO = 'False'
} 

export enum PROCESS_STATUS {
    FAILED = 'Failed',
    YES = 'True',
    NO = 'False',
} 

class CIStore {        

    consents ={
        check_participation: null,
        check_request: null,
        check_adult: null,
        check_permission: null,
        check_contact: null,          
    }

    email = ''
    code = ''
    token = ''

    images:Record<string, IItem> = {}
    
    currentImageUid = null
    currentPersonIndex = -1

    emailValidationStarted = PROCESS_STATUS.NO
    emailValidated = PROCESS_STATUS.NO

    registered = PROCESS_STATUS.NO

    bundleSubmitted:PROCESS_STATUS = PROCESS_STATUS.NO;


    validationDao:ValidationDAO;

    submitDao:SubmitDAO;

    stats=[]

    itemCount = 0
  
    constructor() {
        this.validationDao = new ValidationDAO();
        this.submitDao = new SubmitDAO();

        makeObservable(this, {
            consents: observable,

            updateConsent: action,

            email: observable,
            code: observable,
            token: observable,

            updateEmail: action,
            updateCode: action,
            updateToken: action,

            images: observable,
            currentImageUid: observable,
            currentPersonIndex: observable,   
            
            updateImages: action,        
            updateCurrentImageUid: action,        
            updateCurrentPersonIndex: action,
            updateImage: action, 
            deleteImage: action,    
            imagesHasFile: action,   
            currentImage: computed,

            registered: observable,
            setRegistered: action,
            submitRegistration: action,
            checkRegistration: action,

            emailValidationStarted: observable,
            setEmailValidationStarted: action,
            startEmailValidation: action,

            emailValidated: observable,
            setEmailValidated: action,
            finishEmailValidation: action,      
            
            bundleSubmitted: observable,
            submitItemsForBundle: action,
            setBundleSubmitted:action,

            itemCount: observable,
            getItemCount: action,
            setItemCount:action,
        })
    }

    updateEmail( val){
        this.email = val
    }

    updateCode( val){
        this.code = val
    }

    updateToken( val){
        this.token = val
    }

    updateConsent(key, val){
        this.consents[key] = val
    }

    updateCurrentImageUid(val){
        this.currentImageUid = val
    }

    updateCurrentPersonIndex(val){
        this.currentPersonIndex = val
    }

    updateImages(val){
        this.images = val
    }

    updateImage(image:IItem) {
        this.images[image.uid] = image
    }

    deleteImage(uid) {
        delete this.images[uid]
        this.currentImageUid = null
        this.currentPersonIndex = -1
    }

    get currentImage() {
        return toJS(this.images[this.currentImageUid ? this.currentImageUid : -1])
    }

    imagesHasFile(fileName){
      return  Object.entries(this.images).some(([key, image]) => {
            return image.file !== undefined && (image.file.name === fileName)
        })
    }

    imageSelfPerson(uid:string) {
        let personID = -1

        if (uid === undefined || uid === null) {
            return personID
        }

        let currentImage = this.images[uid ]

        if (currentImage === undefined ) {
            return personID
        }

        let persons = currentImage.persons

        if (persons === undefined ) {
            return personID
        }

        persons.forEach((person:IPerson, index:number) => {
            if (person.consent === PERMISSION.SELF) {
                personID = index 
            }
        })

        return personID
    }

    startEmailValidation() {
        this.emailValidationStarted = PROCESS_STATUS.NO
        this.emailValidated = PROCESS_STATUS.NO

        this.validationDao.startEmailValidation(this.email).then( (startValResult:any) => {
            if(startValResult.errors.length > 0) {
                this.setEmailValidationStarted(PROCESS_STATUS.FAILED)
            } else {
                this.setEmailValidationStarted(PROCESS_STATUS.YES)
            }
        }) ;
    }

    finishEmailValidation() {
        this.emailValidationStarted = PROCESS_STATUS.NO
        this.validationDao.finishEmailValidation(this.email, this.code).then( (startValResult:any) => {
            if(startValResult.errors.length > 0) {
                this.setEmailValidated(PROCESS_STATUS.FAILED)
            } else {
                this.setEmailValidated(PROCESS_STATUS.YES)
            }
        }) ;
    }

    setEmailValidationStarted(val:PROCESS_STATUS) {
        this.emailValidationStarted = val
    }

    setEmailValidated(val:PROCESS_STATUS) {
        this.emailValidated = val
    }

    setRegistered(val:PROCESS_STATUS) {
        this.registered = val
    }

    setBundleSubmitted(val:PROCESS_STATUS) {
        this.bundleSubmitted = val
    }

    setItemCount(val) {
        this.itemCount = val
    }
    
    submitRegistration() {
        this.registered = PROCESS_STATUS.NO
        const permissions:string[] = []

        if (this.consents['check_participation'] !== undefined && this.consents['check_participation']) {permissions.push('Research use [data subject]') }
        if (this.consents['check_request'] !== undefined && this.consents['check_request']) {permissions.push('Contact for new or external use') }
        if (this.consents['check_adult'] !== undefined && this.consents['check_adult']) {permissions.push('Confirm adults') }
        if (this.consents['check_permission'] !== undefined && this.consents['check_permission']) {permissions.push('Confirm consent') }
        if (this.consents['check_contact'] !== undefined && this.consents['check_contact']) {permissions.push('Contact to convey use details') }

        this.validationDao.register(this.email, permissions).then( (registerResult:any) => {
            if(registerResult.errors.length > 0) {
                this.setRegistered(PROCESS_STATUS.FAILED)
            } else {
                this.setRegistered(PROCESS_STATUS.YES)
            }
        }) ;
    }

    checkRegistration(token:string) {
        this.registered = PROCESS_STATUS.NO

        this.validationDao.checkRegistration(token).then( (registerResult:any) => {
            if(registerResult.errors.length > 0) {
                this.setRegistered(PROCESS_STATUS.FAILED)
            } else if (registerResult.content === undefined){
                this.setRegistered(PROCESS_STATUS.FAILED)
            } else {
                this.setRegistered(PROCESS_STATUS.YES)
                this.updateToken(token)
                
                for (const consentpermission of registerResult.content.consents[0].consentpermissions) {
                    if (consentpermission.permissionId.label === 'Research use [data subject]') {this.updateConsent('check_participation', true) }
                    if (consentpermission.permissionId.label === 'Contact for new or external use') {this.updateConsent('check_request', true) }
                    if (consentpermission.permissionId.label === 'Confirm adults') {this.updateConsent('check_adult', true) }
                    if (consentpermission.permissionId.label === 'Confirm consent') {this.updateConsent('check_permission', true) }
                    if (consentpermission.permissionId.label === 'Contact to convey use details') {this.updateConsent('check_contact', true) }
                }
                
                this.updateEmail(registerResult.content.contribAgentId.agentdetails[0].email)

            }
        }) ;
    }

    submitItemsForBundle() {
        this.bundleSubmitted = PROCESS_STATUS.NO 
        
        this.submitDao.doSubmit(this.email, this.token, Object.values(this.images)).then( (result:any) => {
            if(result.errors.length > 0) {
                this.setBundleSubmitted(PROCESS_STATUS.FAILED)
            } else {
                this.setBundleSubmitted(PROCESS_STATUS.YES) 
            }
        }) 
    }

    pingServer(txn:string) {
        this.validationDao.pingServer(txn)
    }

    sendContact(name:string, email:string, msg:string, type:string){
        this.submitDao.doContact(name, email, msg, type)
    }

    getItemCount() {       
        this.submitDao.getItemCount().then( (result:any) => {
            if(result.errors.length === 0) {
                this.setItemCount(result.content[0]['itemcount'])
            }
        }) 
    }  

  }


  
  export default CIStore;