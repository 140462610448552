import React, {useEffect, useContext}  from 'react'

import { useMatomo } from '@jonkoops/matomo-tracker-react'

import { Container, Header, } from 'semantic-ui-react';

import Stores  from "../stores/StoreCollection";   

const Explanation1 = (props) => {
  const valStore = useContext(Stores).valStore

  // Track page view
  const { trackPageView } = useMatomo()

  useEffect(() => {
        trackPageView({
      documentTitle: 'Explanation page 3',
    })
    
    validate()
  }); 

  function validate () {
    //wizard manager will use the name of this default export
    valStore.setPageValid(props.pageName, true)
  }

  return (
    <Container textAlign='left'>
     
      <Header textAlign='center'size='huge'>Safety</Header>
        
        <Header size="medium">Possible benefits and risks to participants</Header>
        <ul>
          <li>
            Your participation benefits society through improving technology used  to fight child sexual abuse and exploitation. 
          </li>
          <li>
            There is no financial reward or other personal benefit to you from participating in the research. 	
          </li>
          <li>
            Data privacy is important to us. However, as with any data collection, there is a small risk that your privacy could be impacted in the event of a data breach. If this occurs we will notify you as soon as practical after we become aware of the matter, and not more than ten business days after it comes to our attention.
          </li>
          <li>
            Participation in the research does not ask you to reflect on (or disclose to researchers) any personal experience you may have of online exploitation or sexual abuse. However, the project may trigger distress if you, or somebody you know, has been personally affected by child sexual exploitation or abuse. If this is the case, and you want to speak to someone about it, we encourage you to contact the following services for advice and support:
          </li>
          </ul>
          
          <div className="indented">
            <p><b>Australia</b></p>
            <p>
              <a href="https://blueknot.org.au/">Blue Knot Foundation</a> – <b>1300 657 380</b> (phone line staffed 9am-5pm AEST, 7 days)
            </p>
            <p>
              <a href="https://bravehearts.org.au/">Bravehearts Inc</a> – <b>1800 272 831</b> (phone line staffed 8:30am to 4:30pm AEST, Monday to Friday)
            </p>
            <p><b>USA</b></p>
            <p>
              <a href="https:/rainn.org/">RAINN</a> – <b>800 656 4673 </b> (U.S.A. Hotline, staffed 24/7)
            </p>
            <p><b>Europe</b></p>
            <p>
              <a href="https://assembly.coe.int/LifeRay/SOC/Pdf/DocsAndDecs/2020/AS-SOC-CHD-INF-2020-02-BIL.pdf">Helplines in Europe</a> – Council of Europe list of help lines.
            </p>            
          </div>

          

        <Header size="medium">Communications and Complaints</Header>
        <p>
          Outcomes arising from the research will be notified publicly on the AiLECS website.
        </p>
        <p>        
          If you are unhappy with the conduct of this research project, and wish to make a complaint, please advise the University by contacting:
        </p>
        <p>
          Executive Officer, Monash University Human Research Ethics Committee (MUHREC) <b>Tl:</b> +61 3 9905 2052	 <b>E:</b> <a href="mailto:muhrec@monash.edu">muhrec@monash.edu</a>,  referencing Project ID 31436.
        </p>
        <p>
          For any other feedback or enquiries, please email us at <a href="mailto:mypicturesmatter@ailecs.org">mypicturesmatter@ailecs.org</a>.
        </p>
    </Container>
   ) 


}

export default Explanation1;