
import { makeObservable, observable, action}  from "mobx";

class ValidationStore {        

    pageValid: {[pageName:string] : boolean}  = {}
    
    constructor() {
        makeObservable(this, {
            pageValid: observable,
            setPageValid: action,   
        })
    }

    setPageValid(index, val) {
        this.pageValid[index] = val
    }

  }
  
  export default ValidationStore;