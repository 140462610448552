import { Header, Grid, Container, Image } from 'semantic-ui-react';
import backgroundMobile from "../assets/AiLECSBannerContainerMobile.jpg";
import background from "../assets/AiLECSBannerContainer.jpg";
import AiLECSlogo from '../assets/AiLECSLogoReverse-155.png';
import Monlogo from '../assets/MonashLogoReverse-155.jpg';

import {useWindowWidth} from '../global/Global'

const BackgroundHeaderBlock = (props) => {
    const  width  = useWindowWidth();

    function goAiLECS() {
        window.open('https://ailecs.org', "_blank")
      }
  
      function goMonash() {
        window.open('https://monash.edu', "_blank")
      }
  
    return (
    <Container style={{ backgroundImage:  props.width > 768? `url(${background}`: `url(${backgroundMobile})`, paddingTop:'5px'}} textAlign='center'>
        <Grid >                
            <Grid.Row centered={true} only="computer">
                <Grid.Column width={3}>
                <Image src={AiLECSlogo} size='small' onClick={goAiLECS} className='pointer marginLeft20'/>
                </Grid.Column>
                <Grid.Column width={10} textAlign='center' >
                    <Header size="huge" className="humongous" inverted><b>MY PICTURES MATTER</b></Header>        
                </Grid.Column>     
                <Grid.Column width={3} textAlign='right'>
                    <Image src={Monlogo} size='small' onClick={goMonash} className='pointer'/>
                </Grid.Column>                       
            </Grid.Row>

            <Grid.Row verticalAlign='bottom' only="computer">                
                <Grid.Column width={16} textAlign='right' >
                    <span className='colorWhite padTop10'>Project ID 31436: Image datasets as machine learning data for countering child sexual exploitation and abuse.</span>                   
                </Grid.Column> 
            </Grid.Row>   

            <Grid.Row centered={true} only="tablet">
                <Grid.Column width={3}>
                <Image src={AiLECSlogo} size='small' className='pointer marginLeft20' onClick={goAiLECS}/>
                </Grid.Column>
                <Grid.Column width={10} textAlign='center' >
                    <Header size="large"  inverted><b>MY PICTURES MATTER</b></Header>        
                </Grid.Column>     
                <Grid.Column width={3} textAlign='right'>
                    <Image src={Monlogo} size='small' onClick={goMonash} className='pointer'/>
                </Grid.Column>                       
            </Grid.Row>

            <Grid.Row  only="tablet">                
                <Grid.Column width={16} textAlign='right' >
                    <span className='colorWhite fontSize07'>Project ID 31436: Image datasets as machine learning data for countering child sexual exploitation and abuse.</span>                   
                </Grid.Column> 
            </Grid.Row> 
           

            <Grid.Row centered={true}  only="mobile">
                <Grid.Column width={4}>
                    <Image src={AiLECSlogo} size='small' onClick={goAiLECS} className='pointer marginLeft5'/>
                </Grid.Column>
                <Grid.Column width={8} textAlign='center' >
                    <Header size="small" inverted><b>MY PICTURES MATTER</b></Header>        
                </Grid.Column>     
                <Grid.Column width={4} >
                    <Image src={Monlogo} size='small' onClick={goMonash} className='pointer padRight5'/>
                </Grid.Column>                       
            </Grid.Row>   
        </Grid>
            { width <  786 && 
                <p className='small-white-text' >Project ID 31436: <br/>Image datasets as machine learning data for countering child sexual exploitation and abuse.</p>                   
            }                          
      
    </Container>
    )
}

export default BackgroundHeaderBlock;