import React, {useContext,  useEffect, useState}  from 'react'

import { useMatomo } from '@jonkoops/matomo-tracker-react'

import {Navigate } from "react-router-dom"; 

import { Container, Header, Card, Image, Grid, Icon, Button} from 'semantic-ui-react';

import {Observer} from 'mobx-react'

import Stores  from "../stores/StoreCollection";

const Submit = (props) => {
    const [doSend, setSend] = useState(false)

    const store = useContext(Stores).ciStore  
    const valStore = useContext(Stores).valStore
    
    // Track page view
    const { trackPageView } = useMatomo()
    //dummy validate so next button is active
    useEffect(() => {
      trackPageView({
        documentTitle: 'Submit bundle',
      })
      validate()
    }); 
  
    function validate () {
      //wizard manager will use the name of this default export
      valStore.setPageValid(props.pageName, true)
    }

    function goSend() {
      store.submitItemsForBundle()
      setSend(true)
    } 

    return (
      <Observer>{() => 
        <Container textAlign='left'>

          {doSend &&
              <Navigate to='/final'/>
          }

          <Header textAlign='center'size='huge'>Send</Header>
          <p>
            By clicking send, you will add the following content to the dataset. A receipt will be sent to <b>{store.email} </b>.
          </p>

          <Grid columns={2} celled >

              {/*------------------------------------------------------
                *  Heading 
                *-------------------------------------------------------*/}
                <Grid.Row only='tablet computer'>
                    <Grid.Column width={14} >
                        <p>
                        <b>I consent to:</b>
                        </p>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign='center' >
                      <b>Consent</b>
                    </Grid.Column>              
                </Grid.Row>

                <Grid.Row only="mobile">
                    <Grid.Column width={16} >
                        <p>
                        <b>I consent to:</b>
                        </p>
                    </Grid.Column>             
                </Grid.Row>


              {/*------------------------------------------------------
                *  First permission 
                *-------------------------------------------------------*/}
                <Grid.Row only='tablet computer'>
                  <Grid.Column width={14} >
                      <p>
                      Inclusion of this material in a research dataset that will be used for developing machine learning technologies to assist in countering online child sexual abuse and exploitation.
                      </p>
                  </Grid.Column>
                  <Grid.Column width={2} textAlign='center' >
                      {store.consents.check_participation? <Icon name='check' color='green'/> : <Icon name='close' color='red'/> }
                  </Grid.Column>             
                </Grid.Row>     

                <Grid.Row only='mobile'>
                  <Grid.Column width={16} >
                      <p>
                      Inclusion of this material in a research dataset that will be used for developing machine learning technologies to assist in countering online child sexual abuse and exploitation.
                      </p>
                   </Grid.Column>                       
                      <Grid.Column width={16} textAlign='center' >
                      {store.consents.check_participation? <Icon name='check' color='green'/> : <Icon name='close' color='red'/> }
                   </Grid.Column>
                </Grid.Row>     
  
              {/*------------------------------------------------------
                *  Second permission 
                *-------------------------------------------------------*/}
                <Grid.Row only='tablet computer'>
                  <Grid.Column width={14} >
                      <p>
                      Being contacted by AiLECS to affirm ongoing consent for the current project, or to request permission for use of this material in other research projects undertaken by the AiLECS Lab or other researchers.
                      </p>
                  </Grid.Column>
                  <Grid.Column width={2} textAlign='center' >
                      {store.consents.check_request ? <Icon name='check' color='green'/> : <Icon name='close' color='red'/> }
                  </Grid.Column>           
                </Grid.Row>

                <Grid.Row only='mobile'>
                  <Grid.Column width={16} >
                      <p>
                      Being contacted by AiLECS to affirm ongoing consent for the current project, or to request permission for use of this material in other research projects undertaken by the AiLECS Lab or other researchers.
                      </p>
                  </Grid.Column>
                  <Grid.Column width={16} textAlign='center' >
                      {store.consents.check_request ? <Icon name='check' color='green'/> : <Icon name='close' color='red'/> }
                  </Grid.Column>           
                </Grid.Row>                

              {/*------------------------------------------------------
                *  Advise heading
                *-------------------------------------------------------*/}
                <Grid.Row >
                  <Grid.Column width={14} >
                      <p>
                      <b>I would like to be advised of:</b>
                      </p>
                  </Grid.Column>                  
                </Grid.Row>

              {/*------------------------------------------------------
                *  Third permission 
                *-------------------------------------------------------*/}
                <Grid.Row only='tablet computer'>
                  <Grid.Column width={14} >
                      <p>
                      Details of projects that make use of a dataset containing my likeness.
                      </p>
                  </Grid.Column>
                  <Grid.Column width={2} textAlign='center' >
                      {store.consents.check_contact ? <Icon name='check' color='green'/> : <Icon name='close' color='red'/> }
                  </Grid.Column>        
                </Grid.Row>    

                <Grid.Row only='mobile'>
                  <Grid.Column width={16} >
                      <p>
                      Details of projects that make use of a dataset containing my likeness.
                      </p>
                  </Grid.Column>
                  <Grid.Column width={16} textAlign='center' >
                      {store.consents.check_contact ? <Icon name='check' color='green'/> : <Icon name='close' color='red'/> }
                  </Grid.Column>        
                </Grid.Row>                   

              {/*------------------------------------------------------
                *  Confirm heading
                *-------------------------------------------------------*/}
                <Grid.Row >
                  <Grid.Column width={14} >
                      <p>
                      <b>I confirm that:</b>
                      </p>
                  </Grid.Column>                
                </Grid.Row>

              {/*------------------------------------------------------
                *  Fourth permission 
                *-------------------------------------------------------*/}                                
                <Grid.Row only='tablet computer'>
                  <Grid.Column width={14} >
                      <p>
                      Myself and any other living persons who are depicted in photo(s) that I am contributing are now aged 18 or older.
                      </p>
                  </Grid.Column>
                  <Grid.Column width={2} textAlign='center' >
                      {store.consents.check_adult ? <Icon name='check' color='green'/> : <Icon name='close' color='red'/> }
                  </Grid.Column>         
                </Grid.Row>

                <Grid.Row  only='mobile'>
                  <Grid.Column width={16} >
                      <p>
                      Myself and any other living persons who are depicted in photo(s) that I am contributing are now aged 18 or older.
                      </p>
                  </Grid.Column>
                  <Grid.Column width={16} textAlign='center' >
                      {store.consents.check_adult ? <Icon name='check' color='green'/> : <Icon name='close' color='red'/> }
                  </Grid.Column>         
                </Grid.Row>                

              {/*------------------------------------------------------
                *  Fifth permission 
                *-------------------------------------------------------*/}   
                <Grid.Row only='tablet computer'>
                  <Grid.Column width={14} >
                      <p>
                      I have permission to contribute images of all persons who appear in photo(s) that I am contributing 
                      </p>
                  </Grid.Column>
                  <Grid.Column width={2} textAlign='center' >
                      {store.consents.check_permission ? <Icon name='check' color='green'/> : <Icon name='close' color='red'/> }
                  </Grid.Column>             
                </Grid.Row>      

                <Grid.Row  only='mobile'>
                  <Grid.Column width={16} >
                      <p>
                      I have permission to contribute images of all persons who appear in photo(s) that I am contributing 
                      </p>
                  </Grid.Column>
                  <Grid.Column width={16} textAlign='center' >
                      {store.consents.check_permission ? <Icon name='check' color='green'/> : <Icon name='close' color='red'/> }
                  </Grid.Column>             
                </Grid.Row>                    
                                
            </Grid>

          <Header as='h4'>The Photo(s) in your contribution:</Header>
          <Card.Group>
            {Object.entries(store.images).map(([key, image], index) =>   
                   
              <Card>
              <Card.Content>
              <Card.Header>{
                // @ts-ignore - Just a weird typescript thing
                image.label}</Card.Header>
                <Image
                  floated='right'
                  size='small'
                  // @ts-ignore - Just a weird typescript thing 
                  src={URL.createObjectURL(image.file)}
                />

                <Card.Meta>{
                // @ts-ignore - Just a weird typescript thing
                image.file.name}</Card.Meta>
                <Card.Description>
                
                  {// @ts-ignore - Just a weird typescript thing 
                  image.persons.length} person(s) described.
                </Card.Description>
              </Card.Content>

            </Card>
            )}
                        
          </Card.Group>
          <p/>
          <Header as='h4'>If all of these details are correct, then click the Send button below to submit your contibution.</Header>

          <Button onClick={goSend} primary>Send</Button>
        </Container>
      }</Observer>
    )
} 

export default Submit;