import React, {useState, useContext, useEffect}   from 'react'

import { useMatomo } from '@jonkoops/matomo-tracker-react'

import { Navigate,  } from 'react-router-dom';
import {Observer} from 'mobx-react'
import { Container, Header, Button, Image, Grid, Divider, } from 'semantic-ui-react';

import {useWindowWidth, getMyHost} from '../global/Global'
import BackgroundHeaderBlock from '../components/BackgroundHeaderBlock'
import Infographic from '../assets/MyPicturesMatter_Infographic_v1.1.png'
import blobhappy from '../assets/blobhappy.png'

import Stores  from "../stores/StoreCollection";
import CIStore from "../stores/CIStore"
import GaugeChart from 'react-gauge-chart'

const Goals = (props) => {
    const [doBack, setBack] = useState(false)
    
    const  width  = useWindowWidth();
    
    // Track page view
    const { trackPageView, trackEvent } = useMatomo()
    useEffect(() => {
      trackPageView({
        documentTitle: 'Goals',
      })
    })
    
    const store:CIStore = useContext(Stores).ciStore  
    store.getItemCount()

    function goBack() {
      setBack(true)
    }

    function downloadInfographic(location) {
      trackEvent({
        category: 'Download',
        action: 'click-event',
        name: 'Infographic', // optional
      })
      window.open(`${getMyHost()}/MyPicturesMatter_Infographic_v1.1.pdf`, "_blank")
    }

    function computeData(imageCount) {

      let percent = 0;

      if (imageCount <= 1000) {
        percent = imageCount / 1000 * .25
      } else if (imageCount <= 10000) {
        percent = .25 + ((imageCount-1000) / 9000 * .25)
      } else if (imageCount <= 50000) {
        percent = .50 + ((imageCount-10000) / 40000 * .25)
      } else {
        percent = .75 + ((imageCount-50000) / 50000 * .25)
      }

      return percent
    }

    return (      
      <Observer>{() =>       
        <Container textAlign='center'>
          {doBack && 
            <Navigate to="/"/>
          }

          <BackgroundHeaderBlock width={width}/>     

          <Header textAlign='center'  size='huge' className='humongous'>Our Goals and Progress<Image src={blobhappy} width='100px' />  </Header> 

          {store.itemCount > 0 &&
            <React.Fragment>              
              <Grid centered >
                <Grid.Row></Grid.Row>
                <Grid.Row className='thin-row'>
                  <Grid.Column width={4}  verticalAlign='middle' textAlign='right'  only="tablet computer">
                  <span className='fontSize11'>1st Milestone <br/>1,000 photos</span>
                  </Grid.Column>
                  <Grid.Column width={4}  verticalAlign='middle' textAlign='right'  only="mobile">
                  <span className='fontSize08 displayInlineBlock lineHeight120'>1st Milestone <br/>1,000 photos</span>
                  </Grid.Column>

                  <Grid.Column width={8}  textAlign='center' only="tablet computer">
                    <p className='fontSize11'>2nd Milestone 10,000 photos</p>
                    
                    <GaugeChart id="gauge-chart3" 
                      nrOfLevels={4} 
                      colors={["#E4FEB0", "#1FAA00"]}
                      needleColor="#8e8e8e"
                      needleBasecolor="#373737"
                      textColor="#000000"
                      marginInPercent={0.01}
                      hideText={true}

                      percent={computeData(store.itemCount)} 
                    />
                  </Grid.Column>

                  <Grid.Column width={8}  textAlign='center' only="mobile">
                    <p className='fontSize07'>2nd Milestone 10,000 photos</p>
                    
                    <GaugeChart id="gauge-chart3" 
                      nrOfLevels={4} 
                      colors={["#E4FEB0", "#1FAA00"]}
                      needleColor="#8e8e8e"
                      needleBasecolor="#373737"
                      textColor="#000000"
                      marginInPercent={0.01}
                      hideText={true}

                      percent={computeData(store.itemCount)} 
                    />
                  </Grid.Column>                  
                  <Grid.Column  width={4} verticalAlign='middle' textAlign='left' only="tablet computer">
                    <span className='fontSize11'>3rd Milestone <br/> 50,000 photos</span>
                  </Grid.Column>
                  <Grid.Column  width={4} verticalAlign='middle' textAlign='left' only="mobile">
                    <span className='fontSize07 displayInlineBlock lineHeight120'>3rd Milestone <br/> 50,000 photos</span>
                  </Grid.Column>                  
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column  width={9}></Grid.Column>
                  <Grid.Column width={7} only="tablet computer"><span className='fontSize11'>Campaign goal: 100,000 photos!</span></Grid.Column>
                  <Grid.Column width={7} only="mobile"><span className='fontSize07 displayInlineBlock lineHeight120'>Campaign goal: <br/>100,000 photos!</span></Grid.Column>
                </Grid.Row>
                <Grid.Row></Grid.Row>
              </Grid>
              
            </React.Fragment>
          }

          <Image src={Infographic} centered/>      

          <p/>
          
          <Container textAlign='center'>
            <Button positive onClick={downloadInfographic }>Download Infographic PDF</Button>
          </Container>

          <Divider/>
            
          <Container textAlign='center'>
            <Button primary onClick={goBack}>Go Back</Button>
          </Container>

          <Container textAlign='center'>
            &nbsp;
          </Container>

        </Container>
      }</Observer>  
    ) 
}

export default Goals;