import BaseDAO from './BaseDAO'

import IItem from '../global/IItem'
import IPerson from '../global/IPerson'

interface  IUploadItem {
    temporal?: string,
    location?: string,
    equipment?: string,
    persons?: IUploadPerson[], 
    fileName?: string, 
    label?: string
    imageWidth?: number,
    imageHeight?: number,

}

interface IUploadPerson {
    age?: string
    //consent: string,
    mediaLocation?: string
}

export default class SubmitDAO  extends BaseDAO {

    doSubmit(email: string, token: string, items:IItem[]) {
        
        const uploadItems: IUploadItem[] = []

        var payload = new FormData()

        for (const item of items) {
            
            if (item.persons === undefined) {
                return this.promiseNormalisedResponse('vcupload', 'No persons defined')
            }

            const itemPersons: IUploadPerson[] = []
            for (const person of item.persons as IPerson[]) {
                const uploadPerson:IUploadPerson = {
                    age: person.age,
                    mediaLocation: `${person.faceX},${person.faceY}`
                }

                itemPersons.push(uploadPerson)
            }

            const uploadItem:IUploadItem = {
                temporal: item.temporal,
                location: item.location,
                equipment: item.equipment,
                persons: itemPersons, 
                fileName: item.file ? item.file.name: '',
                label: item.label,
                imageWidth: item.imageWidth,
                imageHeight: item.imageHeight,
            }

            uploadItems.push(uploadItem)
            payload.append(item.file.name, item.file, item.file.name)
        }

        let content = JSON.stringify(
            {
                agent: {
                    email: email,
                    token: token,
                },
                items: uploadItems
            }
        );

        payload.append("content", content);

        return this.doMultipartPost('vcupload', payload);
    }
        
    doContact(name:string, email: string, msg:string, type:string) {

        let payload = JSON.stringify(
            {
                name: name,
                email: email,
                msg: msg,
                type:type

            }
        );

        return this.doPost('vccontact', payload);
    }

    getItemCount() {
        return this.doGet('vcitemcount');
    }    
}    