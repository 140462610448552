import React, {useEffect, useContext,}  from 'react'

import { useMatomo } from '@jonkoops/matomo-tracker-react'

import { Container, Header, } from 'semantic-ui-react';

import Stores  from "../stores/StoreCollection";   

const Explanation1 = (props) => {
  const valStore = useContext(Stores).valStore

 useContext(Stores).ciStore.pingServer('')
 
  // Track page view
  const { trackPageView } = useMatomo()

  useEffect(() => {
    trackPageView({
      documentTitle: 'Explanation page 1',
    })

    validate()
  }); 

  function validate () {
    //wizard manager will use the name of this default export
    valStore.setPageValid(props.pageName, true)
  }

  return (
    <Container textAlign='left'>
    
      <Header textAlign='center'size='huge'>Participation</Header>
      
      <Header size="medium">What will my photos be used for?</Header>
        <ul>
          <li>
            Your photos, and any descriptive information you give us about them (but not your email address), will be added to a research dataset used to improve the reliability of technologies that detect and classify child exploitation material. 
          </li>
          <li>
            We will not publish your photos, share your photos with anyone else, or use them for any other purpose, without your permission. 
          </li>
        </ul>

      <Header size="medium">What does my research consent mean?</Header>
        <ul>
          <li>
            Research consent means you know about, and agree to, images in which you appear being used in the research. 
          </li>
          <li>
            We can only use your photos if you actively agree to participate.
          </li>
          <li>
            Your participation in the research is voluntary. If you change your mind, you can withdraw permission to use your photos by contacting <a href="mailto:mypicturesmatter@ailecs.org">mypicturesmatter@ailecs.org</a>. 
          </li>
        </ul>

        <Header size="medium">What are you asking me to do?</Header>
        <ul>
          <li>
            If you choose to take part in the project, we will ask you to upload one or more photos using an online form. 
          </li>
          <li>
            To access this form, we will first ask you to actively select permissions relating to research consent, and electronically sign your consent declaration by typing a valid email address. We will use this email address to send you a link to the form, and confirmation emails. We will not use your email address for any other purpose unless you give us permission to do so.
          </li>
          <li>
            You will be asked to provide whatever information about each image you know: the decade when the photo was taken; the geographic location; and the age(s) of yourself and any other people in the photo at the time it was created. If there are other people in the photo, you need to confirm that they are now over the age of 18, and that you have permission to share the image(s). 
          </li>
          <li>
            This should take about 10 minutes of your time.
          </li>
        </ul>
    </Container>
   ) 


}

export default Explanation1;