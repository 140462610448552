import React, {useContext, useState, useEffect}  from 'react'
import {Navigate } from "react-router-dom"; 

import { useMatomo } from '@jonkoops/matomo-tracker-react'

import { Container, Header, Grid, Image, Button} from 'semantic-ui-react';

import {Observer} from 'mobx-react'

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";

import Stores  from "../stores/StoreCollection";
import CIStore from "../stores/CIStore"

import {useWindowWidth} from '../global/Global'
import BackgroundHeaderBlock from '../components/BackgroundHeaderBlock'

import  Final_a_1 from '../assets/Final_a_1.png'
import tcitpLogo from '../assets/tcitp-logo.png'
import AiLECSLogoReverse from '../assets/AiLECS_LOGO_INLINE_REVERSE.png'
import notepad from '../assets/notepad.png'
import bullhorn from '../assets/bullhorn.png'
import workplace_logo from '../assets/workplace_icon1.png'
import twitter_logo from '../assets/twitter_logo.png'
import facebook_logo from '../assets/facebook_logo.png'
import linkedin_logo from '../assets/linkedin_logo.png'
import whatsapp_logo from '../assets/whatsapp_logo.png'
import email_logo from '../assets/email_logo.png'

const Receipt = (props) => {

    const store:CIStore  = useContext(Stores).ciStore 
    const [doFeedback, setFeedback] = useState(false)
    
    // Track page view
    const { trackPageView, trackEvent } = useMatomo()

    const  width  = useWindowWidth();

    useEffect(() => {      
      trackPageView({
        documentTitle: 'Final',
      })
      window.scrollTo(0, 0)
  })

    function AiLECSImage() {
      return (
        <Container className='backgroundBlack pad5-10 marginTop10'>
          <Image src={AiLECSLogoReverse} className='pointer' onClick={goAiLECS}/>
        </Container>
      )
    }

    function CITPImage() {
      return (
        <div className="ui backgroundBlack marginLeftAuto marginRightAuto pad5-10" >
          <Image src={tcitpLogo} className='pointer'  onClick={goCITP} centered/>
        </div>
      )
    }

    function FeedbackImage() {
      return (
        <Container className="backgroundBlack marginTop10 pad5-0" >
          <Button size='huge' inverted  onClick={goFeedback}>Send us feedback!</Button>
        </Container>

      )
    }

    function goAiLECS() {
      trackEvent({
        category: 'Outbound',
        action: 'click-event',
        name: 'https://ailecs.org',
        href: 'https://ailecs.org', // optional
      })

      window.open('https://ailecs.org', "_blank")
    }

    function goCITP() {
      trackEvent({
        category: 'Outbound',
        action: 'click-event',
        name: 'https://childreninthepictures.org',
        href: 'https://childreninthepictures.org', // optional
      })

      window.open('https://childreninthepictures.org/', "_blank")
    }

    function goFeedback() {
      setFeedback(true)
    }

    const shareUrl = "https://mypicturesmatter.org"
    const shareSubject = "My Pictures Matter"
    const shareBody = "I've just contributed some of my childhood photos to the AiLECS lab for machine learning research. Stand with us against online child abuse."
    const shareTag = "MyPicturesMatter"
    const shareIconSize = 45

    function SocialShare(props) {
      return (
        <Grid   columns={6} >
          <Grid.Row></Grid.Row>
          {width > 1200 &&
            <Grid.Row columns='equal' only='computer'>
              <Grid.Column>
                <TwitterShareButton
                  url={shareUrl}
                  title={shareBody}
                  hashtags={[shareTag]}
                >                              
                  <div className="share-icon" style={{width: props.iconSize}}>
                    <Image src={twitter_logo}  width={props.iconSize } />
                  </div> 
                </TwitterShareButton>
              </Grid.Column>
              <Grid.Column>
                <FacebookShareButton
                  url={shareUrl}
                  quote={`${shareBody} #${shareTag}`}
                >
                  <div className="share-icon" style={{width: props.iconSize}}>
                    <Image src={facebook_logo}  width={props.iconSize } />
                  </div>                 
      
                </FacebookShareButton>   
              </Grid.Column>
              <Grid.Column>
                <WhatsappShareButton
                url={shareUrl}
                title={`${shareBody} #${shareTag}`}
                separator=" - "
                >
                  <div className="share-icon" style={{width: props.iconSize}}>
                    <Image src={whatsapp_logo}  width={props.iconSize } />
                  </div> 
                </WhatsappShareButton>                           
              </Grid.Column>
              <Grid.Column>                                        
                <LinkedinShareButton 
                  title={shareSubject}
                  summary={`${shareBody} #${shareTag}`}
                  source={shareUrl}
                  url={`${shareUrl}?1`}
                >
                  <div className="share-icon" style={{width: props.iconSize}}>
                    <Image src={linkedin_logo} width={props.iconSize } />
                  </div>
                </LinkedinShareButton>   
              </Grid.Column>
              <Grid.Column>
                <WorkplaceShareButton
                  url={shareUrl}
                  quote={`${shareBody} #${shareTag}`}
                >
                  <div className="share-icon" style={{width: props.iconSize*0.96}}>
                    <Image src={workplace_logo} width={props.iconSize }/>
                  </div>
                </WorkplaceShareButton>    
              </Grid.Column>
              <Grid.Column>
                <EmailShareButton
                  url={shareUrl}
                  subject={shareSubject}
                  body={`${shareBody} #${shareTag}`}
                >
                  <div className="share-icon" style={{width: props.iconSize}}>
                    <Image src={email_logo} width={props.iconSize } />
                  </div>
                </EmailShareButton>
              </Grid.Column>             
            </Grid.Row>
          }     

          {width < 1200 &&
            <React.Fragment>
              <Grid.Row columns='equal' >
                <Grid.Column>
                  <TwitterShareButton
                    url={shareUrl}
                    title={shareBody}
                    hashtags={[shareTag]}
                  >                              
                    <div className="share-icon" style={{width: props.iconSize}}>
                      <Image src={twitter_logo}  width={props.iconSize } />
                    </div>
                  </TwitterShareButton>
                </Grid.Column>
                <Grid.Column>
                  <FacebookShareButton
                    url={shareUrl}
                    quote={`${shareBody} #${shareTag}`}
                  >
                    <div className="share-icon" style={{width: props.iconSize*0.96}}>
                      <Image src={facebook_logo} width={props.iconSize }/>
                    </div>
                  </FacebookShareButton>   
                </Grid.Column>
                <Grid.Column>
                  <WhatsappShareButton
                  url={shareUrl}
                  title={`${shareBody} #${shareTag}`}
                  separator=" - "
                  >
                    <div className="share-icon" style={{width: props.iconSize}}>
                      <Image src={whatsapp_logo}  width={props.iconSize } />
                    </div> 
                  </WhatsappShareButton>                           
                </Grid.Column>
              </Grid.Row> 
                

              <Grid.Row columns='equal' >    
                <Grid.Column>                                        
                  <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
                    <div className="share-icon" style={{width: props.iconSize}}>
                      <Image src={linkedin_logo} width={props.iconSize } />
                    </div>
                  </LinkedinShareButton>   
                </Grid.Column>          
                <Grid.Column>
                  <WorkplaceShareButton
                    url={shareUrl}
                    quote={`${shareBody} #${shareTag}`}
                  >
                    <div className="share-icon" style={{width: props.iconSize*0.96}}>
                      <Image src={workplace_logo} width={props.iconSize } />
                    </div>
                  </WorkplaceShareButton>    
                </Grid.Column>
                <Grid.Column>
                  <EmailShareButton
                    url={shareUrl}
                    subject={shareSubject}
                    body={`${shareBody} #${shareTag}`}
                  >
                    <div className="share-icon" style={{width: props.iconSize*0.96}}>
                      <Image src={email_logo} width={props.iconSize } />
                    </div>
                  </EmailShareButton>
                </Grid.Column>  
              </Grid.Row>  
          </React.Fragment>  
          }          
        </Grid>
      )
    }
    
    return (
      <Observer>{() => 
          <Container textAlign='left'> 
              {doFeedback && 
                <Navigate to="/feedback"/>
              }

              <BackgroundHeaderBlock width={width}/>   
              <p/>

                <Grid>

                  <Grid.Row textAlign='center' only='tablet computer'>
                    <Grid.Column width={16} >
                      <Header textAlign='center'  size='huge' className='humongous'>Submission received!</Header>
                      <p/>
                    </Grid.Column>
                  </Grid.Row>   

                  <Grid.Row textAlign='center' only='mobile'>
                    <Grid.Column width={16} >
                      <p/>
                      <Header textAlign='center'  size='huge' >Submission received!</Header>
                      <p/>
                    </Grid.Column>
                  </Grid.Row>  
                </Grid>
                  
                {store.email !== '' &&
                  <p>We have sent an email receipt for your contribution to: <b>{store.email}</b>.</p>
                } 
                
                <Container >

                  <Grid centered columns={2} >
                    <Grid.Row></Grid.Row>

                    <Grid.Row only="tablet computer">
                        <Grid.Column width={6} >
                        <Grid  centered padded >
                            <Grid.Row>
                              <Header size='large' className="friendly-font">THANKS FOR STANDING WITH US IN THE FIGHT AGAINST ONLINE CHILD ABUSE!</Header>
                            </Grid.Row>
                            <Grid.Row></Grid.Row>

                            <Grid.Row>
                              <Image src={Final_a_1} />
                            </Grid.Row>
                          </Grid>
                          
                        </Grid.Column>
                        <Grid.Column width={6}  >
                          <Grid  centered padded >
                            <Grid.Row>
                              <Header size='large' className="friendly-font">Want to do more?</Header>
                            </Grid.Row>

                            {/*-------------------------------------------------
                              * Tell Friends
                              --------------------------------------------------*/}
                            <Grid.Row>
                              <Container className="action-pill">
                                <Grid >
                                  <Grid.Row>
                                    <Grid.Column width={13}>
                                      <p className="friendly-font">
                                        Tell a friend about the MY PICTURES MATTER campaign, and invite them to add their childhood photos! 
                                      </p>
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                    <Image src={bullhorn} />
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                              </Container>
                            </Grid.Row>

                            {/*-------------------------------------------------
                              * Social Share icons 
                              --------------------------------------------------*/}
                            <Grid.Row>
                              <Container className="action-pill" textAlign='center'>
                                <span className="friendly-font">Share the call to action on social media! #MyPicturesMatter</span>
                                <SocialShare iconSize={shareIconSize}/>
                                
                              </Container>
                            </Grid.Row>    

                            {/*-------------------------------------------------
                              * Campaigns
                              --------------------------------------------------*/}
                            <Grid.Row>
                              <Container className="action-pill" textAlign='center'>
                                <p className="friendly-font">Check out related awareness and impact campaigns! </p>
                                <CITPImage/>
                              
                              </Container>
                            </Grid.Row>                        
                          </Grid>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row only="mobile" >
                        <Grid.Column width={16}  textAlign='center'>
                          <Grid  centered  >
                            <Grid.Row>
                              <Header size='large' className="friendly-font">THANKS FOR STANDING WITH US IN THE FIGHT AGAINST ONLINE CHILD ABUSE!</Header>
                            </Grid.Row>

                            <Grid.Row></Grid.Row>

                            <Grid.Row>
                              <Image src={Final_a_1} />
                            </Grid.Row>

                            <Grid.Row>
                              <Header size='large' className="friendly-font">Want to do more?</Header>
                            </Grid.Row>

                            {/*-------------------------------------------------
                              * Tell Friends
                              --------------------------------------------------*/}
                            <Grid.Row>
                              <Container className="action-pill-mobile">
                                <Grid >
                                  <Grid.Row>
                                    <Grid.Column width={12}>
                                      <p className="friendly-font">
                                        Tell a friend about the MY PICTURES MATTER campaign, and invite them to add their childhood photos! 
                                      </p>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                    <Image src={bullhorn} />
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                              </Container>
                            </Grid.Row>

                            {/*-------------------------------------------------
                              * Social Share icons 
                              --------------------------------------------------*/}
                            <Grid.Row columns="equal">
                              <Container className="action-pill-mobile force-width" textAlign='center' >
                                <span className="friendly-font">Share the call to action on social media! #MyPicturesMatter </span>
                                <SocialShare iconSize={shareIconSize}/>
                              </Container>
                            </Grid.Row>    

                            {/*-------------------------------------------------
                              * Campaigns
                              --------------------------------------------------*/}
                            <Grid.Row centered>
                              <Container className="action-pill-mobile" textAlign='center'>
                                <p className="friendly-font">Check out related awareness and impact campaigns!</p>
                                <CITPImage/>
                                
                              </Container>
                            </Grid.Row>                                                                                             
                          </Grid>
                        </Grid.Column> 
                    </Grid.Row>

                    <Grid.Row  only='tablet computer'>
                      
                        {/*-------------------------------------------------
                          * Feedback
                          --------------------------------------------------*/}
                        <Grid.Column width={6}  >                
                          <Container className="action-pill" textAlign='center'>
                            <Grid >
                              <Grid.Row>
                                <Grid.Column width={3}>
                                  <Image src={notepad} />
                                </Grid.Column>
                                <Grid.Column width={13} textAlign='left'>
                                  <p className="friendly-font">
                                    Got something to say about our project or about the website?
                                  </p> 
                                  
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                            <FeedbackImage/>                            
                          </Container>              
                        </Grid.Column>

                        {/*-------------------------------------------------
                          * AiLECS
                          --------------------------------------------------*/}
                        <Grid.Column width={6}  >
                          <Container className="action-pill" textAlign='center'>
                              <p className="friendly-font">Visit the AiLECS website to learn more about why AI technology is a vital part of the fight against CSAM. </p>
                              <AiLECSImage/>
                              
                          </Container>
        
                        </Grid.Column>                      
                    </Grid.Row>   


                    <Grid.Row centered only='mobile'>                    
                        {/*-------------------------------------------------
                          * Feedback
                          --------------------------------------------------*/}
              
                          <Container className="action-pill" textAlign='center'>
                            <Grid >
                              <Grid.Row >
                                <Grid.Column width={3} centered>
                                  <Image src={notepad} />
                                </Grid.Column>
                                <Grid.Column width={13} textAlign='left'>
                                  <p className="friendly-font">
                                    Got something to say about our project or about the website?
                                  </p> 
                                  
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                            <FeedbackImage/>                            
                          </Container>              
                
                    </Grid.Row>                

                    <Grid.Row centered only='mobile'>                      
                        {/*-------------------------------------------------
                          * AiLECS
                          --------------------------------------------------*/}
                
                          <Container className="action-pill" textAlign='center'>
                              <p className="friendly-font">Visit the AiLECS website to learn more about why AI technology is a vital part of the fight against CSAM. </p>
                              <AiLECSImage/>
                              
                          </Container>
        
                                  
                    </Grid.Row>                    
                  </Grid>
                </Container>                              
          </Container>

      }</Observer>
    )
} 

export default Receipt;