import { Header, Grid, Image } from 'semantic-ui-react';
import logo from '../assets/AiLECSLogo-155.png';

const PartialHeaderBlock = (props) => {

    function goAiLECS() {
        window.open('https://ailecs.org', "_blank")
      }

    return (
        <div >
            <Grid verticalAlign='middle' >
                <Grid.Row ></Grid.Row>
                <Grid.Row only="tablet computer"  >
                    <Grid.Column width={8} >
                        <Image src={logo} size='small' onClick={goAiLECS} className='pointer'/>
                    </Grid.Column>   
                </Grid.Row>

                <Grid.Row only="mobile">
                    <Grid.Column width={10} >
                        <Image src={logo} size='small' onClick={goAiLECS} className='pointer'/>
                    </Grid.Column>
                    <Grid.Column width={6} textAlign='center' >
                        <Header size="small" ><b>{props.content}</b></Header>
                    </Grid.Column>        
                </Grid.Row>                
 
        </Grid>
    </div>
    )
}

export default PartialHeaderBlock;