import {useState, useEffect}  from 'react'

    // api URL prefix
export function getUrlPrefix() {
    return getUrlHost() + "api/"
}

export function getMultipartUrlPrefix() {
    return getUrlHost() + "apiu/"
}

export function getUrlHost() {

    if (process.env.REACT_APP_SERVER_URL !== undefined) {
        return process.env.REACT_APP_SERVER_URL
    }

    return "http://localhost:1234/"
    //return "https://valid-server-zkbrhi773q-km.a.run.app/"

}

export function getMyHost() {

    if (process.env.REACT_APP_CLIENT_URL !== undefined) {
        return process.env.REACT_APP_CLIENT_URL
    }

    return "http://localhost:3000/"
    //return "https://valid-server-zkbrhi773q-km.a.run.app/"

}

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    }   
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
}

export function useWindowWidth() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions['width'];
}
