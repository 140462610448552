import React, {useContext}  from 'react'

import { Container } from 'semantic-ui-react';

import Explanation1 from '../pages/Explanation1'
import Explanation2 from '../pages/Explanation2'
import Explanation3 from '../pages/Explanation3'
import Consent from '../pages/Consent'

import FullHeaderBlock from './FullHeaderBlock';
import PartialHeaderBlock from './PartialHeaderBlock';
import WizardPageManager from './WizardPageManager';

import Stores  from "../stores/StoreCollection";
import CIStore  from "../stores/CIStore"

const steps =
[
  //make sure both pageNames are the same
  {label: 'Participation', pageName:'Expl1', component: <Explanation1 pageName='Expl1'/>, nextText:"Data Handling"},
  {label: 'Data Handling', pageName:'Expl2', component: <Explanation2 pageName='Expl2'/>, nextText:"Safety"},
  {label: 'Safety', pageName:'Expl3', component: <Explanation3 pageName='Expl3'/>, nextText:"Consent"},
  
  {label: 'Consent', pageName:'Perm', component: <Consent pageName='Perm'/>},

]

const WizardPart1 = ()=> {

  const store:CIStore  = useContext(Stores).ciStore 

  function firstPageHeader() {
    return (
      <FullHeaderBlock content1='Project ID 31436:' content2='Image datasets as machine learning data for countering child sexual exploitation and abuse.' full={true}/>
    )
  }

  function PageHeader() {
    return (
      <PartialHeaderBlock full={true}/>
    )
  }

  return (
    <Container textAlign='center' >
      <Container className='step-progress' fluid>
        <WizardPageManager steps={steps} validate={true} showSteps={true} hideBackButtonOnLast={true} firstPageHeader={firstPageHeader()} pageHeader={PageHeader()} 
        firstBack="/"
        step={store.email !=='' ? 3 : 0}/> {/* If we've already been through the consent form, redirrect back there*/}
      </Container>
    </Container>
  )
}
export default WizardPart1;
