import {getUrlPrefix, getMultipartUrlPrefix} from '../global/Global'

export interface IResponse {
    api_version : string;
    messages : string;
    errors : [];
    content : string;
}

export default abstract class BaseDao {    
    normaliseResponse = (action: String, response: any)  => {
        let result: { message: String, errors: Array<String>, content: any } = {
            message: "",
            errors: [],
            content: {}
        };

        if(typeof(response) == 'string') {
            result.message = 'Internal Error'
            result.errors.push(response)
        } else if(response.error) {
            result.message = 'Error - ' + action + ': ' + response.error;
            result.errors.push(response.message)
        } else if(response.errors && response.errors.length > 0) {
            result = response;
        } else if(response instanceof Blob) {
            result.message = 'Success - ' + action;
            result.content = response
        } else if(response.constructor === Array) {
            result.message = 'Success - ' + action;
            result.content = response
        } else {
            result.message = 'Success - ' + action;
            //console.log('response in base dao', response);
            result.content = response.content;
        }
        return result;

    };

    promiseNormalisedResponse = (action: String, response: any) => {
        let result = this.normaliseResponse(action, response)
        let promise = new Promise(function(resolve, reject) {
            resolve(result)
        });

        return promise;

    };

    doPost(transaction:string, payload:string) {
        let url = getUrlPrefix() + transaction;
        
        return fetch(url, {
            method: 'POST',
            mode: "cors",
            body: payload,
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse(transaction, responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse(transaction, e.message)
        });
    }

    doGet(transaction:string) {
        let url = getUrlPrefix() + transaction;
        
        return fetch(url, {
            method: 'GET',
            mode: "cors",
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse(transaction, responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse(transaction, e.message)
        });
    }

    doMultipartPost(transaction:string, payload:FormData) {
        let url = getMultipartUrlPrefix() + transaction;
        
        return fetch(url, {
            method: 'POST',
            mode: "cors",
            body: payload,
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse(transaction, responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse(transaction, e.message)
        });
    }    
}