import React, {useState, useEffect}   from 'react'

import { useMatomo } from '@jonkoops/matomo-tracker-react'

import {Navigate } from "react-router-dom"; 

import { Container, Header, Button, Card, Image } from 'semantic-ui-react';

import {useWindowWidth} from '../global/Global'
import BackgroundHeaderBlock from '../components/BackgroundHeaderBlock'
import questionblobby from '../assets/questionblobby.png'

const FAQ = (props) => {
  const [doBack, setBack] = useState(false)
    
    const  width  = useWindowWidth();

    
  // Track page view
  const { trackPageView } = useMatomo()
  useEffect(() => {
    trackPageView({
      documentTitle: 'FAQ',
    })
  })

    function goBack() {
      setBack(true)
    }

    function QA(props) {
      return (
        <Card fluid>
          <Card.Content textAlign='left'>
            <Card.Header>{props.q}</Card.Header>
            <Card.Description>{props.a}</Card.Description>
          </Card.Content>
        </Card>
      )
    }

    return (      
      <Container textAlign='center'>
        {doBack && 
          <Navigate to="/"/>
        }

        <BackgroundHeaderBlock width={width}/>     

        <Header textAlign='center'  size='huge' className='humongous'>Frequently Asked Questions <Image src={questionblobby} width='100px' />  </Header>         

        <Card fluid>
          <Card.Content textAlign='left'>
            <Card.Header>I think that a child I know may be a victim of sexual abuse or online sexual exploitation. What should I do?</Card.Header>
            <Card.Description>
              <p>
                If you believe a child is being abused or sexually exploited, report your concerns to your local police, or, in Australia, to the <a href="https://www.accce.gov.au/report">Australian Centre to Counter Child Exploitation (ACCCE)</a>.
              </p>
              <p>
                Our project can't directly assist if you have concerns that a child you know is experiencing abuse or is sharing nude photos with others online. We are developing technology that can support child exploitation investigations, but we don't carry out those investigations ourselves.
              </p>
              <p>
                If you or somebody you know needs someone to talk to, in Australia, <a href="https://www.esafety.gov.au/about-us/counselling-support-services">these services</a> are here to help.
              </p>
            </Card.Description>
          </Card.Content>
        </Card>

        <QA q='Can I submit videos?'
            a='No - for now, our research team is only collecting still photographs. '/>

        <QA q='Can there be adults in my photos?'
            a='Yes! As long as a) there is at least one child in the photo, and b) everyone in the photo has given you permission to share the photo for research use. '/>


        <QA q='I thought I had permission to share a photo of me and my friend/sibling/etc, but I was wrong. What can I do if I’ve uploaded a photo that I shouldn’t have shared?'
            a='Don’t stress, mistakes happen. Let us know by emailing the research team on mypicturesmatter@ailecs.org and we will get in touch with you to make sure we remove the images that don’t have everyone’s permission for research use from the dataset.'/>


        <QA q='What if I change my mind and no longer want a photo to be used for research?'
            a='That’s fine. Our project is centred on consent. Let us know that your preferences have changed by emailing the research team on mypicturesmatter@ailecs.org and we will get in touch with you to make sure we remove the images that you no longer want to be used from the dataset.'/>


        <QA q='What does “remove the images … from the dataset” actually mean? '
            a='We will delete the original file you uploaded, and any copies of the image, from our servers. If you have given permission for your photos to be shared with other researchers, we will contact all research projects who have been given access to the photo(s) to advise them that you have withdrawn consent for that image to be used for research purposes.'/> 

        <Card fluid>
          <Card.Content textAlign='left'>
            <Card.Header>What kind of photos do you want?</Card.Header>
            <Card.Description>
              <p>
                School photos, selfies, milestone celebrations, everyday moments from your childhood.
              </p>
              <p>
                ‘Safe’  photos only please! We are not collecting images that show any form of child nudity, illegal activity, or depictions of violence or abuse. 
              </p>              
            </Card.Description>
          </Card.Content>
        </Card>

        <Card fluid>
          <Card.Content textAlign='left'>
            <Card.Header>What do you mean by ‘safe’ photos? </Card.Header>
            <Card.Description>
              <p>
                ‘Safe’ photos means children are not shown in scenarios or activities that are, or could be construed as being, unsafe or exploitative. This means no imagery showing illegal or harmful activity by or toward children (such as drug taking, underage drinking, or violence), and no imagery that depicts or could be construed as child exploitation if mishandled or taken out of context (this is why we can’t accept photos showing any kind of child nudity, even if the situation shown in the photograph is completely innocent). 
              </p>
              <p>
                We recognise that a ‘safe’ photo does not mean the child/ren depicted had a ‘safe’ childhood. Our research is not attempting to detect or predict the presence of hidden abuse. We are researching technology that makes assessments of what is directly shown in images, to help counter the production and distribution of child sexual abuse material. 
              </p>              
            </Card.Description>
          </Card.Content>
        </Card>

        <QA q='Do you only want ‘happy’ photos? Do I have to be smiling?'
            a='No! We are training machine learning to recognise images that are ‘safe’ or ‘benign’, not just ‘happy’ ones. For the dataset to be most effective, we need to build up a diverse set of photos across all kinds of attributes, including a range of facial expressions.'/>

        <QA q='My pictures are old (taken before digital cameras existed) and/or I only have print photos. Can I still help?'
            a='Yes, you can still contribute your photos! You can upload scans of your print photographs, or "photos of photos" taken using a phone or other digital device.'/>

        <QA q='What file types and image resolution does the project require for submissions?'
            a='We’re not specifying these parameters: well work with what we get. Higher resolution is preferable, but you can still contribute digital photos that have been saved or scanned at a low image resolution. We understand that not everyone has time, equipment, or bandwidth to scan or upload images at very high resolution - and that’s totally fine!'/>

        <QA q='Can I submit more than one photo?'
            a='Yes - we encourage it. You can upload multiple photos in a submission, and you can also make another submission later if you find more photos that you’d like to contribute.'/>

        <QA q='Is there a limit to how many submissions I can make?'
            a='No; you can make as many submissions as you like. Share away! '/>

        <QA q='Why do I have to complete the consent form again when I submit more photos?'
            a='This is a trade off to maximise privacy protections for contributors - we aren’t creating “user accounts” as such. Our system only uses your email address to manage the research consent and use permissions for a specific “bundle” of images (timestamped as one or more photos uploaded together), and does not seek to link data about your movements or use of the My Pictures Matter website to that email address. You’re welcome to send us feedback if user accounts are a feature you would like.'/>

        <QA q='I read something online that said the image collection is for an Australian Federal Police dataset. Are you sharing my photos with the police?'
            a='No. This is a Monash University project and your photographs will be stored and managed by Monash University. Our research lab (AiLECS) is a collaboration with the Australian Federal Police, however this relationship does not grant the AFP, or any other police agency, automatic access to research data that we collect (in this case, your photographs).'/>

        <Card fluid>
          <Card.Content textAlign='left'>
            <Card.Header>Are there any circumstances where police would be given access to photos I upload?</Card.Header>
            <Card.Description>
              <ol>
                <li>
                  We are not collecting images of illegal activity. Any photos that depict sexual abuse or exploitation of children will be reported to law enforcement (and would not be retained by us).
                </li>
                <li>
                  With your permission, photos you contribute to this project can be shared with other researchers, including police researchers, providing a specific set of requirements are all met. These are:

                  <ol  type='a'>
                    <li>
                      On the My Pictures Matter consent form, you have elected to be contacted about other research uses; 
                    </li>
                    <li>
                      You have subsequently been contacted about, and have agreed to, your photos being shared with a police agency for a specific research purpose;
                    </li>
                    <li>                      
                      Prior to any request being made to you, that research has obtained formal (documented) ethics approval from both the police agency involved and Monash University; and,
                    </li>
                    <li>                              
                      The new research maintains your right to withdraw consent for use of your image(s) in that research if you choose to do so in the future.
                    </li>
                  </ol>
                </li>
              </ol>
            </Card.Description>
          </Card.Content>
        </Card>

        <QA q='I lodged a consent form, but I haven’t received a link to upload photos. What do I do?'
            a='First, check your spam folder in case the email has been misidentified as junk mail. If it’s not there, please let us know about the problem via the contact form on the website (remember to include your email address or we won’t be able to respond to the enquiry!), or by sending an email to mypicturesmatter@ailecs.org. If we have your consent record in our system we’ll reply and send you an upload link. If we don’t have the consent record, we’ll let you know. '/>


        <Card fluid>
          <Card.Content textAlign='left'>
            <Card.Header>I sent you a message but no one responded. What’s going on? </Card.Header>
            <Card.Description>
              <p>
                It may take us a day or two to respond to your message but - as long as you have provided your email address on the contact form (or emailed us directly) - you should definitely have received a reply within ten business days. If you haven’t received a response after this time, please check the storage quota for your mailbox - messages we send won’t reach you if your storage mailbox is at capacity. 
              </p>
              <p>
                If you use the ‘contact us’ form to ask a question, please include your email address on that form! The field is optional, as we are happy to accept anonymous feedback, however we have no way of replying to messages that don’t include an email address.l. 
              </p>              
            </Card.Description>
          </Card.Content>
        </Card>
        
        <Container textAlign='center'>
          <Button primary onClick={goBack}>Go Back</Button>
        </Container>

      </Container>
    ) 
}

export default FAQ;