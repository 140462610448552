import React, {useState, useContext, useEffect, useRef}  from 'react'

import { useMatomo } from '@jonkoops/matomo-tracker-react'

import RUG, { DragArea, DropArea,  } from 'react-upload-gallery'
// Add style manually
import 'react-upload-gallery/dist/style.css' // or scss

import { Container, Header, Segment, Icon, Button, Image, Card, Radio, Grid, Modal, Input, Select, Message, Table} from 'semantic-ui-react';

import {Observer} from 'mobx-react'

import Stores  from "../stores/StoreCollection";
import {PERMISSION}  from "../stores/CIStore";
import IPerson from '../global/IPerson'
import IItem from '../global/IItem'

const equipOptions = [
  { key: 'dummy-equip-value', value: '', text: '', selected: false  },
  { key: 'phone', value: 'phone', text: 'Mobile Phone', },
  { key: 'camera', value: 'camera', text: 'Digital Camera' },
  { key: 'scan', value: 'scan', text: 'Scan or photo of a printed image' },
  { key: 'unknown', value: 'unknown', text: "I don't know" },
]

const dateRanges = [
  { key: 'dummy-date-value', value: '', text: '', selected: false  },  
  { key: 'early', value: 'early', text: 'Before 1970', selected: false  },
  { key: 'seventies', value: 'seventies', text: '1970-1979' },
  { key: 'eighties', value: 'eighties', text: '1980-1989' },
  { key: 'nineties', value: 'nineties', text: '1990-1999' },
  { key: 'noughties', value: 'noughties', text: '2000-2009' },
  { key: 'teens', value: 'teens', text: '2010-2019' },
  { key: 'twenties', value: 'twenties', text: '2020-' },
  { key: 'unknown', value: 'unknown', text: "I don't know"},  
]

const ACCEPTED_FILE_TYPES=['jpg', 'jpeg', 'gif', 'png', 'bmp', 'tiff']

const Images = (props) => {
  //const Images = forwardRef((props, ref) => {
  const rugRef = useRef();

  const store = useContext(Stores).ciStore
  const valStore = useContext(Stores).valStore

  const [modalOpen, setModalOpen] = useState(false)
  const [personModalOpen, setPersonModalOpen] = useState(false)
  const [fileWarning, setFileWarning] = useState(false)

  // Track page view
  const { trackPageView } = useMatomo()  
  
  useEffect(() => {
        trackPageView({
      documentTitle: 'Upload photos',
    })
    
    validate()
  }); 

  function validate() {
    valStore.setPageValid(props.pageName, checkValidated() )
  }

  function checkValidated() {
    if (Object.keys(store.images).length === 0){
        return false
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_ignore, image] of Object.entries(store.images) ) {
      if (!validateImage(image)) {
        return false
      }   
    }
    return true
  }  

  function validateImage(image) {

    if (!image){
      return false
    }
    
    if (!image.temporal || !image.location || !image.equipment) {
      return false
    }

    if (image.persons.length < 1){
      return false
    }  
    
    //Need at least one self permission on photo
    if(store.imageSelfPerson(image.uid) < 0) {
      return false
    }

    let agesCheck = true;
    image.persons.forEach((person) => {
      if (!validatePerson(person)) {
        agesCheck = false;
      }      
    })

    return agesCheck;
  }  

    
  function validatePerson (person) {
    return person.age && person.age !=="" && person.consent !== PERMISSION.UNKNOWN
  }      

  function handleImageListChange(eventImages) {
      eventImages.forEach( (ei, index) => {

        if ( (store.imagesHasFile(ei.file.name) ) )  {

          if (isRUGDuplicateImage(ei) ) {
            //file has already been uploaded so remove it from the upload widget
            ei.remove()
          }
         
        } else{
          let image:IItem = {
            uid: ei.uid,
            temporal:'',
            location:'',
            equipment:'',
            persons: [], 
            file: ei.file, 
            label:''
          }

          store.updateImage(image)
          validate()
        }         
      });
    }

    function isRUGDuplicateImage(image) {
      let result = false

      let matchIndex = -1

      // @ts-ignore we know the Rug ref etc. is good
      if (rugRef && rugRef.current && rugRef.current.state) {
        // @ts-ignore we know the Rug ref etc. is good
        rugRef.current.state.images.forEach( (candidate, index) => {

          if (candidate.file.name === image.file.name) {
            if (matchIndex < 0) {
              matchIndex = index
            } else {
              //we've seen this filename before
                if (candidate.uid === image.uid){
                  // it's this image that is the duplicate so flag it as such
                  result = true
                }
            }
          }
        });
      }

      return result
    }

    function handleImageDelete (image) {
      if (image.uid  in store.images) {
        store.deleteImage(image.uid )
        validate()       
      } 
    }

    function handleMetadataChange (field, value) {      

      if (store.currentImageUid  in store.images) {
        let image = store.currentImage
        image[field] = value
        
        store.updateImage(image)
 
        validate()        
      }      
    }

    //Handle when an image is clicked - i.e. start a new person entry
    function imageClick (evt){

      let person:IPerson = {
        age: '',
        consent: PERMISSION.UNKNOWN,

        faceX: Math.round(evt.clientX - evt.target.getBoundingClientRect().left),
        faceY: Math.round(evt.clientY - evt.target.getBoundingClientRect().top)        
      }

      // If we haven't captured the image dimensions yet, then do so now.
      let image:IItem = store.currentImage
      if (image.imageHeight === undefined) {
        image.imageWidth = Math.round(evt.target.width);
        image.imageHeight = Math.round(evt.target.height);

        store.updateImage(image)
      }

      //store.imageAddperson(image, person)
      
      let persons = store.currentImage.persons
      
      //Add the new person
      persons.push(person)

      handleMetadataChange('persons', persons )

      store.updateCurrentPersonIndex(persons.length -1)

      togglePersonModal()        
    }    

    function handleRemovePerson (evt, ix) {
      evt.preventDefault();
      let persons = store.currentImage.persons

      let index = parseInt(ix)
      if (index > -1 && index < persons.length) {
        persons.splice(index, 1)
        handleMetadataChange('persons', persons)
        store.updateCurrentPersonIndex(-1)
      }
    }

    function handleEditPerson(ix) {
      let persons = store.currentImage.persons

      let index = parseInt(ix)
      if (index > -1 && index < persons.length) {
        store.updateCurrentPersonIndex(index)
        togglePersonModal()
      }
    }

    // from edit person modal
    function handlePersonChange (field, value) {

      let persons = store.currentImage.persons

      persons[store.currentPersonIndex][field] = value

      handleMetadataChange('persons', persons)
    }


    function getCurrentPerson() {
      return store.currentImage.persons[store.currentPersonIndex]
    }

    //Handle when a given image is selected
    function handleSelect (event, image)  {
      store.updateCurrentImageUid(image.uid)
      store.updateCurrentPersonIndex(-1)
      toggleModal()
    }

    //Toggle the main image metadata modal
    function toggleModal ()  {
        setModalOpen(!modalOpen) 
    }  

    //Toggle the secondary person metadata modal
    function togglePersonModal (){
        setPersonModalOpen(!personModalOpen)  
    }  
    
    //invalid file type upload
    function handleWarning(type, rules){
      setFileWarning(true)
    }

    //initial state for the RUG widget
    let files = Object.entries(store.images).map(([key, image], index) => {
      return image
    }) 

    //Image Metadata Fields
    function ImageMetadataFields() {
      return (
        <React.Fragment>
          Title of this photo (optional).
          <Input fluid placeholder='Enter a title for this photo'
            value={store.currentImage.label}
            onChange={(evt, data) => handleMetadataChange( 'label', data.value) }
          />  
          <p/>
          When was this photo taken? 
          <Select label="When taken" fluid options={dateRanges} placeholder='Select year range' 
            clearable
            error={!store.currentImage.temporal}
            value={store.currentImage.temporal}
            onChange={(evt, data) => handleMetadataChange('temporal', data.value) }
             />
          <p/>
          Where was this photo taken?
          <Input fluid placeholder='Enter geographical location (e.g. city and country)'
            error={store.currentImage.location === ''}
            value={store.currentImage.location}
            onChange={(evt, data) => handleMetadataChange( 'location', data.value) }
          />                        
          <p/>
          How was this image produced?
          <Select label="Equipment" fluid options={equipOptions} placeholder='Select device'
            clearable
            error={!store.currentImage.equipment}
            value={store.currentImage.equipment}
            onChange={(evt, data) => handleMetadataChange('equipment', data.value) }                        
          />
          <p/>  
          {store.currentImage.persons.length === 0 &&
            <Message negative >
              <Message.Header>You need to add the age/permission details of everyone in the photo (We can't use this photo without permission)</Message.Header>
              <Message.List items={['To add age/permission details, click on each face in the photo.',
              'In the popup window, type the age of the person selected, and choose the permission for use.',
              'You must be present in the photo.'
              ]} />
            </Message>
          }

          {store.currentImage.persons.length > 0 &&
            <Message warning >
              <Message.Header>Make sure you add details of every person in this photo.                           
              </Message.Header>
              <Message.List items={["",'To add age/permission details, click on other faces in the photo',
              'To change the age/permission details of a person, click on their circle.',
              "To remove a person's details, right-click on their circle.",
              'Remember, you must be present in the photo.']} />
            </Message>
          }  
        </React.Fragment>
      )
    }

    //Image Card
    function ImageCard() {
      return (
          <Card fluid>
          <Image src={URL.createObjectURL(store.currentImage.file)} onClick={imageClick} />

            {store.currentImage.persons.map((person, index) =>
              <div>
                <div key={index} style={{position: "absolute",top: person.faceY-12, left:person.faceX-12}}>
                  <Icon  onClick={(evt) => handleEditPerson(index)} key={index} name='circle outline' area-hidden="false" className='white-pin' size='large'                               
                        onContextMenu={(evt) => handleRemovePerson(evt,index)}
                  />
                </div>                              
                <div style={{position: "absolute",top: person.faceY-12, left:person.faceX-2}} key={index} >
                  <span className='large white-pin' 
                      onClick={(evt) => handleEditPerson(index)} key={index}
                      onContextMenu={(evt) => handleRemovePerson(evt,index)}>
                    {index +1}  
                  </span>
                </div>
              </div>
            )}
        
          <Card.Content className='fill-card'>

          { store.currentImage.persons.length === 0 &&
            <Header size='medium' className='red-text'>No ages specified in photo yet.</Header>
          }

          { store.currentImage.persons.length > 0 &&

          <Table columns={3}   unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Age</Table.HeaderCell>
                <Table.HeaderCell>Consent</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
        
            {store.currentImage.persons.map((person, index) =>  
              <Table.Row>
                <Table.Cell width={1}>{index+1}</Table.Cell>
                {person.age  && <Table.Cell width={2}>{person.age}</Table.Cell>}
                {!person.age && <Table.Cell width={2} negative>*</Table.Cell>}

                {person.consent !==PERMISSION.UNKNOWN && <Table.Cell width={3}>{person.consent}</Table.Cell>}
                {person.consent ===PERMISSION.UNKNOWN && <Table.Cell width={3} negative>*</Table.Cell>}
              </Table.Row>                  
            )}                       
          </Table.Body>
        </Table>  
        }     

        </Card.Content>
      </Card>
      )
    }
    return (
      <Observer>{() => 
        <Container textAlign='left'>
          <Header textAlign='center'size='huge'>Photos</Header>
          <Header size="medium">Welcome back! Thanks for standing with us against child abuse.</Header>

          <p>Add photos for your contribution here. Describe each photo before submitting.</p>

          {fileWarning &&
            <Message negative onDismiss={() => {setFileWarning(false)}}>
              <Message.Header>Upload file not a photo</Message.Header>
                <p> 
                  One or more files were not a valid photo format. They have been omitted.
                </p>
            </Message>
          }
          <RUG ref={rugRef} flags={{}}
            initialState ={files}
            accept={ACCEPTED_FILE_TYPES}
            inOrder={true} 
            onChange={handleImageListChange} 
            onDeleted={handleImageDelete}
            onWarning={handleWarning}
            header={({ openDialogue }) => (
                <div>
                <DropArea>
                {
                  (isDrag) => 
                  <div>
                    <Segment textAlign='center' secondary={isDrag} >
                      <Icon name='photo' size='massive'/>
                      <Header>Drag and drop photos here</Header>
                      <Header>or</Header>
                      <Button size='large' primary content="Select photos for upload" onClick={openDialogue} />
                    </Segment>

                  </div>                      
                }
                </DropArea>

                </div>                
              )}
              >

              
              <DragArea className="photo-grid ui four column grid center aligned " >                
                {
                  (image) => (        
                      <Card className='sep-card'>
                        
                        <Image src={URL.createObjectURL(image.file) } onClick={(e) => handleSelect(e, image)}
                                label={{
                                  as: 'a',
                                  color: validateImage(store.images[image.uid]) ? 'green' : 'orange',
                                  content: validateImage(store.images[image.uid]) ? 'Description OK': 'Click to describe Photo',
                                  icon: validateImage(store.images[image.uid])? 'check' : 'pencil',
                                  ribbon: 'right',
                                  onClick:  image.select,
                                }}                             
                        />
                        <Card.Content>

                        {store.images[image.uid] && store.images[image.uid].label &&
                          <Card.Header>
                                  {store.images[image.uid].label}
                          </Card.Header>
                        }

                        <Card.Description>
                          <Grid columns={16}>
                            <Grid.Row >
                              <Grid.Column width={14}>
                                {image.file.name}
                              </Grid.Column>

                              <Grid.Column width={2}>
                                <Icon name='trash' link color='red' float='right' onClick={image.remove}/>
                              </Grid.Column>     
                            </Grid.Row>                                                                                  
                          </Grid>
           
                        </Card.Description>
                      </Card.Content>
                      </Card>                      
                  )
                }
             
              </DragArea>     

 
        
          </RUG >

          {store.currentImageUid !== null && store.currentImage !== null &&
          <Modal
            onClose={() => toggleModal()}
            onOpen={() => toggleModal()}
            open={modalOpen}
          >
            <Modal.Header>Describe Photo</Modal.Header>
            <Modal.Content >
              <Modal.Description>

                <Grid >
                    <Grid.Row only='tablet computer' columns={16}>
                      <Grid.Column width={6} >
                          <ImageCard/>                                                                                                  
                      </Grid.Column>

                      <Grid.Column width={10}>
                         <ImageMetadataFields/>
                      </Grid.Column>     
                    </Grid.Row> 

                    <Grid.Row only='mobile' columns={16}>
                      <Grid.Column width={16} >
                          <ImageCard/>   
                          <ImageMetadataFields/>                                                                                               
                      </Grid.Column>
                    </Grid.Row>                                                           
                </Grid>
  
                          

              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                content="Close"
                onClick={() => toggleModal()}
                primary
              />
            </Modal.Actions>
          </Modal>     
          }          

          {store.currentPersonIndex > -1 && getCurrentPerson() !== null &&
          <Modal size='mini'
            onClose={() => togglePersonModal()}
            onOpen={() => togglePersonModal()}
            open={personModalOpen}
          >
            <Modal.Header>Describe Person</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
              <Grid columns={16}>
                    <Grid.Row>
                      <Grid.Column width={10}>
                      <Card >

                            <Image src={URL.createObjectURL(store.currentImage.file)} 
                            />
                            {store.currentPersonIndex > -1 &&                             
                              <div>
                              <div style={{position: "absolute",top: getCurrentPerson().faceY-12, left:getCurrentPerson().faceX-12}}>
                                <Icon  name='circle outline' area-hidden="false" className='white-pin' size='large' />                                                                                               
                              </div>                              
                              <div style={{position: "absolute",top: getCurrentPerson().faceY-12, left:getCurrentPerson().faceX-2}}>
                                <span className='large white-pin'>{store.currentPersonIndex +1}</span>
                              </div>
                            </div>                              
                            }               
                        </Card>
                          <p/>
                          How old was this person in the photo?
                          <Input fluid placeholder='Enter approximate age of person'
                            value={getCurrentPerson().age}
                            onChange={(evt, data) => handlePersonChange( 'age', data.value) }
                          />                        
                          <p/>

                            <Radio
                              label='This is me.'
                              name='personConsent'
                              value={PERMISSION.SELF}
                              checked={getCurrentPerson().consent === PERMISSION.SELF}
                              onChange={(evt, data) => handlePersonChange( 'consent', data.value) }
                              disabled = { store.imageSelfPerson(store.currentImage.uid) > -1 && store.imageSelfPerson(store.currentImage.uid) !== store.currentPersonIndex}
                            />   
                         

                          <Radio
                            label='I have consent from this person to contribute this photo.'
                            name='personConsent'
                            value={PERMISSION.OBTAINED}
                            checked={getCurrentPerson().consent === PERMISSION.OBTAINED}
                            onChange={(evt, data) => handlePersonChange( 'consent', data.value) }
                          />                         
                        </Grid.Column>     
                    </Grid.Row>                                                        
                </Grid>  
                    
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                content="Close"
                onClick={() => togglePersonModal()}
                primary
              />
            </Modal.Actions>
          </Modal>     
          }  
        </Container>
      }</Observer>    
    ) 
}

export default Images;