import React, {useState, useEffect}   from 'react'
import {Navigate } from "react-router-dom"; 

import { Container, Header, Divider, Image, Grid } from 'semantic-ui-react';

import { useMatomo } from '@jonkoops/matomo-tracker-react'


import {useWindowWidth} from '../global/Global'
import BackgroundHeaderBlock from '../components/BackgroundHeaderBlock'

import Initial_cta from '../assets/landing/Initial_cta.png';
import Initial_facebook from '../assets/landing/Initial_facebook.png'; 
import Initial_contact from '../assets/landing/Initial_contact.png'; 

import ComicPanels_1 from '../assets/landing/ComicPanels_1.png';
import ComicPanels_2 from '../assets/landing/ComicPanels_2.png';
import ComicPanels_3 from '../assets/landing/ComicPanels_3.png';
import ComicPanels_4 from '../assets/landing/ComicPanels_4.png';
import ComicPanels_5 from '../assets/landing/ComicPanels_5.png';
import ComicPanels_6 from '../assets/landing/ComicPanels_6.png';

import ComicPanels_FAQ from '../assets/landing/ComicPanels_FAQ.png';
import ComicPanels_Start from '../assets/landing/ComicPanels_Start.png';
import ComicPanels_Goals from '../assets/landing/ComicPanels_Goals.png';

import versioning from '../versioning.json';

const App = (props) => {
  const [doStart, setStart] = useState(false)
  const [doFAQ, setFAQ] = useState(false)
  const [doGoals, setGoals] = useState(false)
  const [doContact, setContact] = useState(false)

    const  width  = useWindowWidth();

    const { trackPageView } = useMatomo()

    // Track page view
    useEffect(() => {
      trackPageView({
        documentTitle: 'Landing page',
      })

      //  @ts-ignore
      if (window.FB !== undefined) {
        //  @ts-ignore
        window.FB.XFBML.parse();
      }
    })    

    function goStart() {
      setStart(true)
    }

    function goFAQ() {
      setFAQ(true)
    }    

    function goGoals() {
      setGoals(true)
    } 

    function goContact() {
      setContact(true)
    } 

    function TopCTAImage() {
      return (
        <Image src={Initial_cta} onClick={goStart} className="pointer"/>
      )
    }

    function StartImage() {
      return (
        <Image src={ComicPanels_Start} onClick={goStart} className="pointer"/>
      )
    }

    function ContactImage(props) {
      if (props.spaced ) {
        return (
          <Image src={Initial_contact} spaced="left" onClick={goContact} className='pointer'/>
        )
      } else {
        return (
          <Image src={Initial_contact} onClick={goContact} className='pointer'/>
        )
      }

    }

    function FAQImage() {
      return (
        <Image src={ComicPanels_FAQ}  onClick={goFAQ} className='pointer'/>
      )
    }

    function GoalsImage(mobile) {
      return (
        <Image src={ComicPanels_Goals} onClick={goGoals} className='pointer'/>
      )
    }

    function FacebookLike(props) {
      return (
        <div className="fb-like fbcentre" data-href="https://www.facebook.com/mypicturesmatter" 
        data-width="" data-layout="button" data-action="like" data-size={props.size} data-share="false"></div>
      )
    }
 
    return (      
      <Container textAlign='center'>
        {doStart && 
          <Navigate to="/registration"/>
        }

        {doFAQ && 
          <Navigate to="/faq"/>
        }

        {doGoals && 
          <Navigate to="/goals"/>
        }        

        {doContact && 
          <Navigate to="/contactus"/>
        }             

          <BackgroundHeaderBlock width={width}/>                   

          <Grid> 
            <Grid.Row only="tablet computer"></Grid.Row>

            {/* ------------------------------------------------------------------
                Headings 
                ------------------------------------------------------------------*/}
            <Grid.Row textAlign='center' only='tablet computer'>
              <Grid.Column width={16} >
                <Header textAlign='center'  size='huge' className='humongous'>Could your childhood photos help save a child from harm?</Header>
              </Grid.Column>
            </Grid.Row>   

            <Grid.Row textAlign='center' only='mobile'>
              <Grid.Column width={16} >
                <p/>
                <Header textAlign='center'  size='large' >Could your childhood photos help save a child from harm?</Header>
              </Grid.Column>
            </Grid.Row>  

            {/* ------------------------------------------------------------------
                Early Calls to action 
                ------------------------------------------------------------------*/}            
            <Grid.Row textAlign='center' verticalAlign='top' only='tablet computer'>     
              <Grid.Column width={4} className="padTop20" >
                    <ContactImage/>
              </Grid.Column>
              <Grid.Column width={4} className="padTop20">
                    <Image src={Initial_facebook} spaced='left'  />
                    <FacebookLike size="large" />
              </Grid.Column>                            
              <Grid.Column width={8} >
                <TopCTAImage/>
              </Grid.Column>
            </Grid.Row>     

            <Grid.Row textAlign='center' centered verticalAlign='top' className="comic-row" only='mobile'>               
              <Grid.Column width={10} >
                <TopCTAImage/>
              </Grid.Column>
            </Grid.Row>   

            <Grid.Row textAlign='center' verticalAlign='top' className="comic-row" only='mobile'>      
              <Grid.Column width={8} >
                <Image src={Initial_facebook}  />
                <FacebookLike size="small"/>
              </Grid.Column>           
              <Grid.Column width={8} >
                <ContactImage spaced/>
              </Grid.Column>
            </Grid.Row>  
            

            {/* ------------------------------------------------------------------
                Comic panels computer
                ------------------------------------------------------------------*/}                                     

            <Grid.Row columns='equal' only='tablet computer'className="comic-row" >
              <Grid.Column className="comic-column">
                <Image src={ComicPanels_1} />
              </Grid.Column>
              <Grid.Column className="comic-column">
                <Image src={ComicPanels_2} />
              </Grid.Column>
              <Grid.Column className="comic-column">
                <Image src={ComicPanels_3} />
              </Grid.Column>
            </Grid.Row>     

            <Grid.Row columns='equal' only='tablet computer' className="comic-row">
              <Grid.Column className="comic-column">
                <Image src={ComicPanels_4} />
              </Grid.Column>
              <Grid.Column className="comic-column">
                <Image src={ComicPanels_5} />
              </Grid.Column>
              <Grid.Column className="comic-column">
                <FAQImage />
              </Grid.Column>
            </Grid.Row>   

            <Grid.Row columns='equal' only='tablet computer' className="comic-row">
              <Grid.Column className="comic-column">
                <Image src={ComicPanels_6} />
              </Grid.Column>
              <Grid.Column className="comic-column">
                <StartImage />
              </Grid.Column>
              <Grid.Column className="comic-column">
                <GoalsImage />
              </Grid.Column>
            </Grid.Row>                                                       

            {/* ------------------------------------------------------------------
                Comic panels mobile
                ------------------------------------------------------------------*/}                                     

            <Grid.Row columns='equal' only='mobile' className="comic-row">
                <Grid.Column className="comic-column">
                  <Image src={ComicPanels_1} />
                </Grid.Column>
                <Grid.Column className="comic-column">
                  <Image src={ComicPanels_2} />
                </Grid.Column>
            </Grid.Row>      

            <Grid.Row columns='equal' only='mobile' className="comic-row">
                <Grid.Column className="comic-column">
                  <Image src={ComicPanels_3} />
                </Grid.Column>
                <Grid.Column className="comic-column">
                  <Image src={ComicPanels_4} />
                </Grid.Column>
            </Grid.Row>   

            <Grid.Row columns='equal' only='mobile' className="comic-row">
                <Grid.Column className="comic-column">
                  <Image src={ComicPanels_5} />
                </Grid.Column>
                <Grid.Column className="comic-column">
                  <FAQImage />
                </Grid.Column>
            </Grid.Row>       

            <Grid.Row columns='equal' only='mobile'className="comic-row" >
                <Grid.Column className="comic-column">
                  <Image src={ComicPanels_6} />
                </Grid.Column>
                <Grid.Column className="comic-column">
                  <StartImage />
                </Grid.Column>
            </Grid.Row>  

            <Grid.Row centered only='mobile' className="comic-row">
                <Grid.Column width="8">
                  <GoalsImage />
                </Grid.Column>
            </Grid.Row>                                   
          </Grid>   
          <Divider/>

          <Container textAlign='left'>
            <p className="tiny-text">
            This research is being conducted by <a href="https:/ailecs.org/">AiLECS Lab</a>, a collaboration between Monash University and the Australian Federal Police. The project has received funding from the Westpac Safer Children Safer Communities Fund and has approval from the Monash University Human Research Committee (Project ID: 31436). Content that you share with us will be stored and managed by Monash University in accordance with guidelines set out in the <a href="https://www.nhmrc.gov.au/about-us/publications/national-statement-ethical-conduct-human-research-2007-updated-2018">Australian National Statement on Ethical Conduct in Human Research</a>. If you would like more information regarding any aspect of this project, email the researchers at <a href="mailto:mypicturesmatter@ailecs.org">mypicturesmatter@ailecs.org</a>, or contact the project’s Chief Investigator: <b>Associate Professor Campbell Wilson, Monash University</b>, Department of Data Science and AI, Phone: +61 3 9903 1142, Email: <a href="mailto:campbell.wilson@monash.edu">campbell.wilson@monash.edu</a> 
            </p>
          </Container>
          <Container textAlign='right'>
            <p className="tiny-text">
            {`V${versioning.buildMajor}.${versioning.buildMinor}.${versioning.buildRevision}`}
            </p>
          </Container>
        </Container>
       ) 
}

export default App;