import { Grid, Image } from 'semantic-ui-react';
import logo from '../assets/AiLECSLogo-155.png';
import monlogo from '../assets/MonashLogo-155.jpg';

import {useWindowWidth} from '../global/Global'

const HeaderBlock = (props) => {
  
    const width  = useWindowWidth();

    function goAiLECS() {
        window.open('https://ailecs.org', "_blank")
      }
  
      function goMonash() {
        window.open('https://monash.edu', "_blank")
      }

    return (
        <div >
            <Grid verticalAlign='middle' >
                <Grid.Row ></Grid.Row>

                <Grid.Row only="tablet computer">
                    <Grid.Column width={3}>
                        <Image src={logo} size='small'  onClick={goAiLECS} className='pointer'/>
                    </Grid.Column>
                    <Grid.Column width={10} textAlign='center' >
                        <p ><b>{props.content1}</b><br/>
                        <b>{props.content2}</b></p>
                    </Grid.Column>     
                    <Grid.Column width={3} >
                        <Image src={monlogo} size='small' onClick={goMonash} className='pointer' />
                    </Grid.Column>                       
                </Grid.Row>
                <Grid.Row only="mobile">
                    <Grid.Column width={6}>
                        <Image src={logo} fluid  onClick={goAiLECS} className='pointer'/>
                    </Grid.Column>
                    <Grid.Column width={4} textAlign='center' >
                        <span className='fontSize07'><b>{props.content1}</b></span>
                    </Grid.Column>     
                    <Grid.Column width={6} >
                        <Image src={monlogo} fluid onClick={goMonash} className='pointer'/>
                    </Grid.Column>                       
                </Grid.Row>          
                { width < 768 &&      
                    <span className='fontSize07'><b>{props.content2}</b></span>     
                }          
  
         
            
        </Grid>
    </div>
    )
}

export default HeaderBlock;