import BaseDAO from './BaseDAO'

export default class ValidationDAO  extends BaseDAO {

    pingServer(entityType: string) {
        return this.doGet(entityType);
    }

    startEmailValidation(email: string) {

        let payload = JSON.stringify(
            {
                email: email
            }
        );

        return this.doPost('validationstart', payload);
    }

    finishEmailValidation(email: string, token:string) {

        let payload = JSON.stringify(
            {
                email: email,
                token: token
            }
        );

        return this.doPost('validationcomplete', payload);        
    }   
    
    register(email: string, permissions:string[]) {

        let payload = JSON.stringify(
            {
                email: email,
                permissions: permissions
            }
        );

        return this.doPost('vcregister', payload);
    }   
    
    checkRegistration(token: string,) {

        let payload = JSON.stringify(
            {
                token: token
            }
        );

        return this.doPost('vccheckregistration', payload);
    }

}    