import React, {useContext, useEffect, useState}  from 'react'
import {Navigate } from "react-router-dom"; 

import { useMatomo } from '@jonkoops/matomo-tracker-react'

import { Container, Header, Grid, Checkbox } from 'semantic-ui-react';

import {Observer} from 'mobx-react'

import {useWindowWidth} from '../global/Global'
import Stores  from "../stores/StoreCollection";
import {PROCESS_STATUS}  from "../stores/CIStore";

import SimpleEmailValidationWidget from "../components/SimpleEmailValidationWidget"

const Consent= (props) => {
    const [isSubmitted, setSubmitted] = useState(false)
    const store = useContext(Stores).ciStore
    const valStore = useContext(Stores).valStore

    const width  = useWindowWidth();

    // Track page view
    const { trackPageView } = useMatomo()

    store.pingServer('')

    useEffect(() => {
        trackPageView({
            documentTitle: 'Consent',
        })
        
        validate()       
    }); 

    function handleChange (evt, data) {

        if (data.value) {
            store.updateConsent(data.name, data.value === 'true' ? true : false )
        } else {
            store.updateConsent(data.name, data.checked )
        }

        validate()        
    }

    function validate () {
        valStore.setPageValid(props.pageName, checkValidated() )
    }
    
    function validateConsent () {
         if(store.consents.check_participation !== true) {
             return false
         }

         if(store.consents.check_adult !== true) {
            return false
        }

        if(store.consents.check_permission !== true) {
            return false
        }

        let result = true
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [_ignore, value] of Object.entries(store.consents)) {
            if (value === null) {
                result = false
            }

        }

        return result
    }
    
    function checkValidated () {     
        return validateConsent() && store.emailValidated === PROCESS_STATUS.YES
    }

    function submitRegistration() {
        store.submitRegistration()
        setSubmitted(true)
    }

    return (
        <Observer>{() => 
            <Container textAlign='left'>

            {isSubmitted &&
                <Navigate to='/registrationsubmitted'/>
            }

            <Header textAlign='center'size='huge'>Consent</Header>
            <Header size="small"><b>Project ID 31436: Image datasets as machine learning data for countering child sexual exploitation and abuse.</b></Header>            

            <p>I have read and understood the project information (Explanatory Statement) and I consent to participate in this project. 
                By entering my email address below, I am electronically signing this consent form.
               
            </p>

            <p>I am contributing photo file(s) containing my visual likenesses to this project and:</p>



            <Grid columns={3} celled={width > 768}>

                {/*------------------------------------------------------
                *  Heading 
                *-------------------------------------------------------*/}
                <Grid.Row only='tablet computer'>
                    <Grid.Column width={12} >
                        <p>
                        <b>I consent to:</b>
                        </p>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign='center' >
                        <b>Yes</b>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign='center' >
                        <b>No</b>
                    </Grid.Column>              
                </Grid.Row>

                <Grid.Row only="mobile">
                    <Grid.Column width={16} >
                        <p>
                        <b>I consent to:</b>
                        </p>
                    </Grid.Column>             
                </Grid.Row>

                {/*------------------------------------------------------
                *  First permission 
                *-------------------------------------------------------*/}
                <Grid.Row only='tablet computer'>
                    <Grid.Column width={12} >
                        <p>
                        Inclusion of this material in a research dataset that will be used for developing machine learning technologies to assist in countering online child sexual abuse and exploitation.
                        </p>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign='center' >
                        <Checkbox
                            name='check_participation'
                            checked={store.consents['check_participation']}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={2} textAlign='center' >
                        <span className='ui tiny-text red-text'>Consent needed to continue</span>
                    </Grid.Column>              
                </Grid.Row>     

                <Grid.Row only='mobile'>
                    <Grid.Column width={16} >
                        <p>
                        Inclusion of this material in a research dataset that will be used for developing machine learning technologies to assist in countering online child sexual abuse and exploitation.
                        </p>
                    </Grid.Column>
                </Grid.Row>     
                <Grid.Row only='mobile'>    
                    <Grid celled padded>
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='center' >
                                <b>Yes</b>
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='center' >
                                <b>No</b>
                            </Grid.Column>     
                        </Grid.Row>     
                        <Grid.Row only='mobile'>                                          
                            <Grid.Column width={8} textAlign='center' >
                                <Checkbox
                                    name='check_participation'
                                    checked={store.consents['check_participation']}
                                    onChange={handleChange}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='center' >
                                <span className='ui tiny-text red-text'>Consent needed to continue</span>
                            </Grid.Column> 
                        </Grid.Row> 
                    </Grid>             
                </Grid.Row>          

                {/*------------------------------------------------------
                *  Second permission 
                *-------------------------------------------------------*/}
                <Grid.Row only='tablet computer'>
                <Grid.Column width={12} >
                    <p>
                    Being contacted by AiLECS to affirm ongoing consent for the current project, or to request permission for use of this material in other research projects undertaken by the AiLECS Lab or other researchers. 
                    </p>
                </Grid.Column>
                <Grid.Column width={2} textAlign='center' >
                    <Checkbox
                        name='check_request'
                        value='true'
                        checked={store.consents['check_request']  === true}
                        onChange={handleChange}
                    />
                </Grid.Column>
                <Grid.Column width={2} textAlign='center' >
                    <Checkbox
                        name='check_request'
                        value='false'
                        checked={store.consents['check_request']  === false}
                        onChange={handleChange}
                    />
                </Grid.Column>              
                </Grid.Row>

                <Grid.Row only='mobile'>
                    <Grid.Column width={16} >
                        <p>
                        Being contacted by AiLECS to affirm ongoing consent for the current project, or to request permission for use of this material in other research projects undertaken by the AiLECS Lab or other researchers. 
                        </p>
                    </Grid.Column>
                </Grid.Row>     
                <Grid.Row only='mobile'>    
                    <Grid celled padded>
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='center' >
                                <b>Yes</b>
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='center' >
                                <b>No</b>
                            </Grid.Column>     
                        </Grid.Row>     
                        <Grid.Row only='mobile'>                                          
                            <Grid.Column width={8} textAlign='center' >
                                <Checkbox
                                    name='check_request'
                                    checked={store.consents['check_request']}
                                    onChange={handleChange}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='center' >
                                <Checkbox
                                    name='check_request'
                                    value='false'
                                    checked={store.consents['check_request']  === false}
                                    onChange={handleChange}
                                />
                            </Grid.Column>  
                        </Grid.Row> 
                    </Grid>             
                </Grid.Row>          

                {/*------------------------------------------------------
                *  Advise Heading 
                *-------------------------------------------------------*/}
                <Grid.Row >
                    <Grid.Column width={12} >
                        <p>
                        <b>I would like to be advised of:</b>
                        </p>
                    </Grid.Column>                    
                </Grid.Row>

                {/*------------------------------------------------------
                *  Third permission
                *-------------------------------------------------------*/}
                <Grid.Row only='tablet computer'>
                    <Grid.Column width={12} >
                        <p>
                        Details of projects that make use of a dataset containing my likeness.
                        </p>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign='center' >
                        <Checkbox
                            name='check_contact'
                            value='true'
                            checked={store.consents['check_contact'] === true}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={2} textAlign='center' >
                        <Checkbox
                            name='check_contact'
                            value='false'
                            checked={store.consents['check_contact'] === false}
                            onChange={handleChange}
                        />
                    </Grid.Column>            
                </Grid.Row>     

                <Grid.Row only='mobile'>
                    <Grid.Column width={16} >
                        <p>
                        Details of projects that make use of a dataset containing my likeness. 
                        </p>
                    </Grid.Column>
                </Grid.Row>     
                <Grid.Row only='mobile'>    
                    <Grid celled padded>
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='center' >
                                <b>Yes</b>
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='center' >
                                <b>No</b>
                            </Grid.Column>     
                        </Grid.Row>     
                        <Grid.Row only='mobile'>                                          
                            <Grid.Column width={8} textAlign='center' >
                                <Checkbox
                                    name='check_contact'
                                    value='true'
                                    checked={store.consents['check_contact'] === true}
                                    onChange={handleChange}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='center' >
                                <Checkbox
                                    name='check_contact'
                                    value='false'
                                    checked={store.consents['check_contact'] === false}
                                    onChange={handleChange}
                                />
                            </Grid.Column>    
                        </Grid.Row> 
                    </Grid>             
                </Grid.Row>                   


                {/*------------------------------------------------------
                *  Confirm Heading 
                *-------------------------------------------------------*/}
                <Grid.Row >
                    <Grid.Column width={12} >
                        <p>
                        <b>I confirm that:</b>
                        </p>
                    </Grid.Column>                
                </Grid.Row>

                {/*------------------------------------------------------
                *  Fifth Permission
                *-------------------------------------------------------*/}
                <Grid.Row only='tablet computer'>
                    <Grid.Column width={12} >
                        <p>
                        Myself and any other living persons who are depicted in photo file(s) that I am contributing are now aged 18 or older.
                        </p>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign='center' >
                        <Checkbox
                            name='check_adult'
                            checked={store.consents['check_adult']}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={2} textAlign='center' >
                        <span className='ui tiny-text red-text'>Consent needed to continue</span>
                    </Grid.Column>             
                </Grid.Row>

                <Grid.Row only='mobile'>
                    <Grid.Column width={16} >
                        <p>
                        Myself and any other living persons who are depicted in photo file(s) that I am contributing are now aged 18 or older. 
                        </p>
                    </Grid.Column>
                </Grid.Row>     
                <Grid.Row only='mobile'>    
                    <Grid celled padded>
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='center' >
                                <b>Yes</b>
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='center' >
                                <b>No</b>
                            </Grid.Column>     
                        </Grid.Row>     
                        <Grid.Row only='mobile'>                                          
                            <Grid.Column width={8} textAlign='center' >
                                <Checkbox
                                    name='check_adult'
                                    checked={store.consents['check_adult']}
                                    onChange={handleChange}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='center' >
                                <span className='ui tiny-text red-text'>Consent needed to continue</span>
                            </Grid.Column>      
                        </Grid.Row> 
                    </Grid>             
                </Grid.Row>          

                {/*------------------------------------------------------
                *  Fifth Permission
                *-------------------------------------------------------*/}
                <Grid.Row only='tablet computer'>
                    <Grid.Column width={12} >
                        <p>
                        I have permission to contribute images of all persons who appear in photo file(s) that I am contributing.
                        </p>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign='center' >
                        <Checkbox
                            name='check_permission'
                            checked={store.consents['check_permission']}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={2} textAlign='center' >
                        <span className='ui tiny-text red-text'>Consent needed to continue</span>
                    </Grid.Column>             
                </Grid.Row>      

                <Grid.Row only='mobile'>
                    <Grid.Column width={16} >
                        <p>
                        I have permission to contribute images of all persons who appear in photo file(s) that I am contributing. 
                        </p>
                    </Grid.Column>
                </Grid.Row>     

                <Grid.Row only='mobile'>    
                    <Grid celled padded>
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='center' >
                                <b>Yes</b>
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='center' >
                                <b>No</b>
                            </Grid.Column>     
                        </Grid.Row>     
                        <Grid.Row only='mobile'>                                          
                            <Grid.Column width={8} textAlign='center' >
                                <Checkbox
                                    name='check_permission'
                                    checked={store.consents['check_permission']}
                                    onChange={handleChange}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='center' >
                                <span className='ui tiny-text red-text'>Consent needed to continue</span>
                            </Grid.Column>      
                        </Grid.Row> 
                    </Grid>             
                </Grid.Row>       
                <Grid.Row only='mobile'></Grid.Row>                                
            </Grid>

            <p>
                We need to verify and store your email address as a record of appropriate consent. 
                We will send you a receipt of your contribution to this address and will use this email address to contact you according to your preferences indicated above.
                We will not disclose your email address to any other party, nor use it for any other purpose.
            </p>

            {store.emailValidated !== PROCESS_STATUS.YES && validateConsent() &&
                <p>
                    Please enter your email address and click the green <b>I Agree</b> button. A link to the photo upload form will be sent to that email address.
                </p>
            }

            <SimpleEmailValidationWidget launchCallback={submitRegistration} enabled={validateConsent()} />

            </Container>
        }</Observer>    
    ) 
}


export default Consent;