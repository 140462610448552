import React from "react";
import CIStore from "./CIStore";
import ValidationStore from "./ValidationStore";

// see https://dev.to/cakasuma/using-mobx-hooks-with-multiple-stores-in-react-3dk4

class StoreCollection  {
  ciStore
  valStore

  constructor() {
    this.ciStore = new CIStore()
    this.valStore = new ValidationStore()
  }
}

const Stores = React.createContext(new StoreCollection());
export default Stores 
// this will be the function available for the app to connect to the stores
//export const Stores = () => React.useContext(StoresContext);